import {
    profile,
} from '../../constants/adminWebServices';

const profileRequest = async (appToken) => {
    let res = await fetch(profile, {
        method: "GET",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + appToken
        })
    });
    return await res.json();
};

export {
    profileRequest
}