import React, { Fragment } from 'react';
import UseLogin from "../../hooks/auth/UseLogin";
import { MdMarkEmailRead } from "react-icons/md";
import { BiSolidMessageAltDetail } from 'react-icons/bi';
import {sendOtpRequest, twoFactorRequest} from "../../sevices/auth/loginService";
import { useNavigate } from 'react-router-dom';
import {sweetAlert} from "../../lib/sweetAlert";



const SelectOtpOption = () => {

    const navigate = useNavigate()

    const handleSelect = async (types) => {
        console.log("types ==== " , types)
        // try {
            let token = localStorage.getItem("access_token");
            const create = await sendOtpRequest(token, types);
            if (create?.status === "success") {
                await sweetAlert('Updated Successfully', create.status);
                navigate('/auth/send-otp')
            } else {
                await sweetAlert('Something Went Wrong', create.status);
            }
        // } catch (e) {
            // console.log(e.messaage);
        // }
    }

    // const [
    //     email,
    //     password,
    //     error,
    //     handleEmail,
    //     handlePassword,
    //     login,
    // ] = UseLogin();

    return (
        <Fragment>
            <section className="sign-in-page">
                <div className="container bg-white mt-5 p-0">
                    <div className="row no-gutters">
                        <div className="col-sm-6 align-self-center">
                            <div className="sign-in-from">
                                <h1 className="mb-0 dark-signin">Select Option</h1>
                                <p>Please select any option to get an OTP.</p>
                                <div className="mt-4">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="card card-hover cursor-pointer text-center border-0 shadow-sm p-5" onClick={() => handleSelect('email')}>
                                                <p className='mb-0 text-muted'>
                                                    <MdMarkEmailRead size={34} />
                                                    <br />
                                                    send otp code via Email
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="card card-hover cursor-pointer text-center border-0 shadow-sm p-5" onClick={() => handleSelect('sms')}>
                                                <p className='mb-0 text-muted'>
                                                    <BiSolidMessageAltDetail size={34} />
                                                    <br />
                                                    send otp code via sms
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sign-info">
                                        <ul className="iq-social-media">
                                            <li><a href="javascript:void(0)"><i className="ri-facebook-box-line" /></a></li>
                                            <li><a href="javascript:void(0)"><i className="ri-twitter-line" /></a></li>
                                            <li><a href="javascript:void(0)"><i className="ri-instagram-line" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 text-center">
                            <div className="sign-in-detail text-white">
                                <a className="sign-in-logo mb-5" href="javascript:void(0)">
                                    <h1 className="text-white">IBROKER</h1>
                                </a>
                                <div className="slick-slider11" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items={1} data-items-laptop={1} data-items-tab={1} data-items-mobile={1} data-items-mobile-sm={1} data-margin={0}>
                                    <div className="item">
                                        <img src="/assets/images/login/1.jpg" className="img-fluid mb-4" alt="logo" />
                                        <h4 className="mb-1 text-white">Manage your orders</h4>
                                        <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default SelectOtpOption;