import { userProfileView } from "../../constants/adminWebServices";

// const getProfile = async (id, appToken) => {
//     let res = await fetch(`${userProfileView}/${id}`, {
//         method: "GET",
//         headers: new Headers({
//             "content-type": "application/json",
//             accept: "application/json",
//             Authorization: "Bearer " + appToken,
//         }),
//     });
//     return await res.json();
// };

const getProfileRequest = async (id, appToken) => {
    let res = await fetch(`${userProfileView}/${id}`, {
      method: "GET",
      headers: new Headers({
        "content-type": "application/json",
        accept: "application/json",
        Authorization: "Bearer " + appToken,
      }),
    });
    return await res.json();
  };


export {
    getProfileRequest,
}