import React, {useEffect, useState} from 'react';
import {agencies} from "../../constants/adminWebServices";
import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
import 'datatables.net-bs4/js/dataTables.bootstrap4'
import $ from 'jquery'
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import {createRoot} from "react-dom/client";
import {deleteAgencyRequest, getAgenciesRequest, getAgencyRequest} from "../../sevices/agency/agencyService";
import {sweetAlert, sweetAlertConfirmation} from "../../lib/sweetAlert";

const UseAgency = () => {
    const navigate = useNavigate();
    const [agencies, setAgencies] = useState([]);
    let token = localStorage.getItem('access_token');
    let newDataTable = '';

    const [filterInput, setFilterInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        getTableData();
    }, []);

    const getTableData = async (page) => {
        setLoading(true);
        try{
            const body = {
                start: page === undefined ? 0 : (page -1) * perPage,
                length: 10,
            }
            const data = await getAgenciesRequest(token, body);
            setAgencies(data.data);
            setTotalRows(data.recordsTotal);
            setLoading(false);
        }catch(e){
            console.log('e', e.message)
            setLoading(false);
        }
        /*newDataTable = $('#crmTable').DataTable({
            "serverSide": true,
            "processing": true,
            "bInfo": true,
            "bPaginate": true,
            "bLengthChange":false,
            "pageLength": 10,
            "ajax":{
                "url": agencies,
                'type': 'POST',
                'beforeSend': function (request) {
                    request.setRequestHeader("Authorization", "Bearer "+token);
                }
            },
            "columns":[
                {data: 'name'},
                {data: 'email'},
                {data: 'phone'},
                {
                    data: 'created_at',
                    'render': function(data, type, row, meta) {
                        return moment(row.created_at).format('DD-MMMM-YYYY h:mm A');
                    }
                },
                {
                    data: 'id',
                    'targets' : 0,
                    createdCell: (data, type, row, meta) => {
                        createRoot(data).render(
                            <>
                                <button onClick={() => goEditRecord(row.id)} type="button" className="btn btn-sm btn-primary mr-2"><i
                                    className="fa fa-pencil-square-o"></i></button>
                                <button onClick={() => deleteRecord(row.id)} type="button" className="btn btn-sm btn-danger mr-2"><i
                                    className="fa fa-trash-o"></i></button>
                            </>
                        );
                    }
                },
            ],
            "bDestroy": true
        });*/
    }

    function goEditRecord(id) {
        navigate(`/add-edit-user/${id}`);
    }

    const deleteRecord = async (id) => {
        let result = await sweetAlertConfirmation();
        if (result.isConfirmed) {
            let res = await deleteAgencyRequest(id, token);
            if (res.status === 'success') {
                await sweetAlert(res.msg, res.status);
                getTableData()
            } else {
                await sweetAlert(res.msg, res.status);
            }
        }
    };

    const handlePageChange = ( page )=> {
        getTableData(page);
    };

    const columns = [
        {
            name: 'Action',
            cell: (row, i) => {
                return (
                    <>
                        <button onClick={() => goEditRecord(row.id)} type="button" className="btn btn-sm btn-primary mr-2">
                            <i className="fa fa-pencil-square-o"/>
                        </button>
                        <button onClick={() => deleteRecord(row.id)} type="button" className="btn btn-sm btn-danger mr-2">
                            <i className="fa fa-trash-o"/>
                        </button>
                    </>
                )
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            name: 'Created At',
            selector: (row) => moment(row.created_at).format('DD-MMMM-YYYY h:mm A'),
            sortable: true,
        },
    ]

    const handleFilter = (e) => {
        setFilterInput(e.target.value)
    }

    const filteredItems = agencies.filter(
        (item) =>
            item.name && item.name.toLowerCase().includes(filterInput.toLowerCase())
    );

    return [
        // deleteRecord,
        columns,
        filteredItems,
        totalRows,
        loading,
        handlePageChange,
        filterInput,
        handleFilter,
    ];
};

export default UseAgency;