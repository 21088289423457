import React,{Fragment} from 'react';
import UseSaveRecord from "../../hooks/agent/UseSaveRecord";
import {Link} from "react-router-dom";

const AddEditAgent = () => {

    const [
        agent,
        error,
        formValues,
        addFormFields,
        removeFormFields,
        handleChange,
        saveAgent,
    ] = UseSaveRecord();

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="iq-edit-list-data">
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade active show"
                                    id="personal-information"
                                    role="tabpanel"
                                >
                                    <div className="iq-card">
                                        <div className="iq-card-header d-flex justify-content-between">
                                            <div className="iq-header-title">
                                                <h4 className="card-title">Agent Skills</h4>
                                            </div>
                                        </div>
                                        <div className="iq-card-body">
                                            <div>
                                                <div className="row align-items-center">
                                                    {formValues.map((element, index) => {
                                                        return (
                                                            <>
                                                                <div className="form-group col-sm-11">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="skill"
                                                                        value={element.skill}
                                                                        onChange={(e) => handleChange(index, e)}
                                                                    />
                                                                </div>
                                                                {(index===0) ?
                                                                    <div className="form-group col-sm-1">
                                                                        <button
                                                                            className="btn btn-primary"
                                                                            onClick={addFormFields}
                                                                        ><i className="fa fa-plus fa-2x" aria-hidden="true" />
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    <div className="form-group col-sm-1">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            onClick={() => removeFormFields(index)}
                                                                        ><i className="fa fa-times fa-2x" aria-hidden="true" />
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                                <button
                                                    onClick={saveAgent}
                                                    type="button"
                                                    className="btn btn-primary mr-2"
                                                >
                                                    Save
                                                </button>
                                                <Link to="/agents" className="btn btn-danger mr-2">
                                                    Cancel
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );
};

export default AddEditAgent;