import {
    changeAgentStatus,
    allStatuses, selectedAgent, getInterViewKanban,
} from "../../constants/adminWebServices";

const allStatusRequest = async (appToken) => {
    let res = await fetch(allStatuses, {
        method: "GET",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + appToken
        })
    });
    return await res.json();
};

const agentStatusChangeRequest = async (body, appToken) => {
    let res = await fetch(changeAgentStatus, {
        method: "POST",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + appToken
        }),
        body: JSON.stringify(body),
    });
    return await res.json();
};

const allInterviewRequest = async (appToken, body) => {
    let res = await fetch(selectedAgent, {
        method: "Post",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + appToken
        }),
        body: JSON.stringify(body),
    });
    return await res.json();
};

const getAllKanaban = async (token) => {
    let res = await fetch(`${getInterViewKanban}`, {
        method: "GET",
        headers: new Headers({
            "content-type": "application/json",
            accept: "application/json",
            Authorization: "Bearer " + token,
        }),
    });
    return await res.json();
};

export {
    allStatusRequest,
    agentStatusChangeRequest,
    allInterviewRequest,
    getAllKanaban
}