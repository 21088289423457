import { useSelector } from 'react-redux';
import { getProfileRequest } from '../../sevices/agent/profileServices';
import { useEffect, useState } from 'react';
import { sweetAlert } from '../../lib/sweetAlert';
import { postAuthData } from '../../constants/Api';
import { userProfileUpdate } from '../../constants/adminWebServices';
import { useDispatch } from 'react-redux';
import { setSaveUser } from '../../features/adminSlice';

const UseProfile = () => {

    let token = localStorage.getItem("access_token");
    const user = useSelector((state) => state.adminSlice.userData)
    const [city, setCity] = useState("");
    const [description, setDescription] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [image, setImage] = useState();

    const id = user?.id

    const dispatch = useDispatch()

    const getRecord = async (id) => {
        let res = await getProfileRequest(id, token);
        if (res.status === 'success') {
            setCity(res?.data?.city)
            setDescription(res?.data?.description)
            setEmail(res?.data?.email)
            setName(res?.data?.name)
            setPhone(res?.data?.phone)
            setImage(res?.data?.profile)
            dispatch(setSaveUser(res?.data))

        }
    };

    // const updateProfile = (e) => {
    //     e.preventDefault()

    //     try {
    //         const data = new FormData()
    //         data.append("id", id)
    //         data.append("name", name)
    //         data.append("email", email)
    //         data.append("phone", phone)
    //         data.append("city", city)
    //         data.append("description", description)
    //         data.append("profile ", image)

    //         postAuthData(userProfileUpdate, data, false)
    //             .then(async (data) => {
    //                 await sweetAlert(data?.message, data?.status)
    //                 setName('');
    //                 setEmail('');
    //                 setPhone('');
    //                 setDescription('');
    //                 setImage('');
    //                 setCity('');
    //                 getRecord(id)
    //             })
    //             .catch(async (err) => {
    //                 await sweetAlert(err?.message, err?.status)

    //             })

    //     } catch (error) {
    //         console.log(eror)
    //     }

    // }

    const updateProfile = async (e) => {
        e.preventDefault();

        try {
            const data = new FormData();
            data.append("id", id);
            data.append("city", city);
            data.append("description", description);
            data.append("email", email);
            data.append("name", name);
            data.append("phone", phone);
            data.append("profile", image);

            const response = await postAuthData(userProfileUpdate, data, false);

            if (response) {

                setName('');
                setEmail('');
                setPhone('');
                setDescription('');
                setImage('');
                setCity('');
                await sweetAlert(response?.message, response?.status);
                getRecord(id);
            } else {
                // Handle the case where response is undefined or not as expected
                console.error("Invalid response received");
            }
        } catch (error) {
            await sweetAlert(error?.message, error?.status);
        }
    };


    useEffect(() => {

        if (id !== undefined) {
            getRecord(id);
        }
    }, [id]);


    return (
        [

            city,
            setCity,
            name,
            setName,
            phone,
            setPhone,
            image,
            setImage,
            email,
            setEmail,
            description,
            setDescription,
            updateProfile
        ]
    )
}

export default UseProfile