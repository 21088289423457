import React from 'react';
import Swal from 'sweetalert2';

const sweetAlert = async (title,icon,timer=3000) => {
    await Swal.fire({
        title: title,
        icon: icon,
        showConfirmButton: false,
        position: 'top-right',
        timer: timer
    });
};

const sweetAlertConfirmation = async () => {
    return await Swal.fire({
        title: 'Are you sure?',
        text: "You want to event this.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, click it!'
    });
};

export {
    sweetAlert,
    sweetAlertConfirmation,
};