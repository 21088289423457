import React, {useState} from 'react';

const UseProfile = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmail = (e) => setEmail(e.target.value)

    const handlePssword = (e) => setPassword(e.target.value)

    const login = () => {

    };

    return [
        email,
        password,
        handleEmail,
        handlePssword,
        login,
    ];
};

export default UseProfile;