import React, {useState,useEffect} from 'react';
import { useParams } from "react-router-dom";
import {getAgentRequest, getAgentSkillsRequest, saveAgentSkillsRequest} from "../../sevices/agent/agentService";
import {sweetAlert} from "../../lib/sweetAlert";

const UseSaveRecord = () => {
    let token = localStorage.getItem('access_token');
    const [error, setError] = useState({});
    const [agent, setAgent] = useState(null);
    const [formValues, setFormValues] = useState([])
    let { id } = useParams();

    useEffect(() => {
        if (id !== undefined) {
            getRecord(id);
        }
    },[id]);

    const getRecord = async (id) => {
        let res = await getAgentSkillsRequest(id,token)
        if (res.status === 'success') {
            if (res?.data?.length > 0 ) {
                setFormValues(res.data)
            } else {
                setFormValues([{ skill: ""}])
            }
        }
    }

    let addFormFields = () => {
        setFormValues([...formValues, { skill: "" }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    const saveAgent = async () => {
        if (formValues.length > 0 && formValues[0]?.skill) {
            let body = {
                skill: formValues,
                agent_id: id,
                agency_id: id,
            }
            let res = await saveAgentSkillsRequest(body, token)
            if (res.status === 'success') {
                await sweetAlert(res.msg, res.status);
                setFormValues(res.data)
                return
            } else {
                await sweetAlert(res.msg, res.status);
                return
            }
        }
        await sweetAlert('Please fill out the skills', 'danger');
    }

    return [
        agent,
        error,
        formValues,
        addFormFields,
        removeFormFields,
        handleChange,
        saveAgent,
    ];
};

export default UseSaveRecord;