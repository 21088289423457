import React, {useState,useEffect} from 'react';
import { useParams } from "react-router-dom";
import {
    getAgentRequest,
    getRelatedAgentsRequest,
    getWorkedAgencyRequest,
    wishlistAgentRequest
} from "../../sevices/agent/agentService";
import {sweetAlert, sweetAlertConfirmation} from "../../lib/sweetAlert";

const UseSaveRecord = () => {
    let token = localStorage.getItem('access_token');
    const [agent, setAgent] = useState(null);
    const [relatedAgent, setRelatedAgent] = useState([]);
    const [agency, setAgency] = useState([]);
    let { id } = useParams();

    const getRecord = async (id) => {
        let res = await getAgentRequest(id,token);
        if (res.status === 'success') {
            setAgent(res.data)
            getRelatedAgents(res?.data?.state);
        }
    };

    const getRelatedAgents = async (state) => {
        let res = await getRelatedAgentsRequest(state,token);
        if (res.status === 'success') {
            setRelatedAgent(res.data)
        }
    };

    const getWorkedAgency = async (agentId) => {
        let res = await getWorkedAgencyRequest(agentId,token);
        if (res.status === 'success') {
            setAgency(res.data)
        }
    };

    useEffect(() => {
        if (id !== undefined) {
            getRecord(id);
            getWorkedAgency(id);
        }
    },[id]);

    const wishlist = async (id) => {
        let result = await sweetAlertConfirmation();
        if (result.isConfirmed) {
            let res = await wishlistAgentRequest(id, token);
            if (res.status === 'success') {
                await sweetAlert(res.msg, res.status);
                await getRelatedAgents(agent?.state)
            } else {
                await sweetAlert(res.msg, res.status);
            }
        }
    };

    return [
        agent,
        relatedAgent,
        wishlist,
        agency,
    ];
};

export default UseSaveRecord;