import React, { Fragment } from 'react';
import UseProfile from "../../hooks/profile/UseProfile";
import { useSelector } from 'react-redux';
import noImage from "../../assets/no-image-found.webp";


const Dashboard = () => {
    const [
        login,
        data
    ] = UseProfile();

    const user = useSelector((state) => state.adminSlice.userData)


    return (
        <Fragment>
            {/* Page Content  */}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Agent Data</h4>
                                </div>
                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                    <div className="dropdown">
                                        <span className="dropdown-toggle" id="dropdownMenuButton1" data-toggle="dropdown">
                                            <i className="ri-more-fill" />
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="dropdownMenuButton1">
                                            <a className="dropdown-item" href="javascript:void(0)"><i
                                                className="ri-eye-fill mr-2" />View</a>
                                            <a className="dropdown-item" href="javascript:void(0)"><i
                                                className="ri-delete-bin-6-fill mr-2" />Delete</a>
                                            <a className="dropdown-item" href="javascript:void(0)"><i
                                                className="ri-pencil-fill mr-2" />Edit</a>
                                            <a className="dropdown-item" href="javascript:void(0)"><i
                                                className="ri-printer-fill mr-2" />Print</a>
                                            <a className="dropdown-item" href="javascript:void(0)"><i
                                                className="ri-file-download-fill mr-2" />Download</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className="m-auto d-block text-center pb-3">
                                    <div className="iq-user mb-3">
                                        {/* <img className="avatar-70 rounded-circle" src="/assets/images/user/03.jpg"
                                            alt="" /> */}

                                        {user?.profile ?
                                            <img src={user?.profile} width={150} height={150} style={{objectFit:"cover"}} className="img-fluid rounded-circle " alt="user" />

                                            :
                                            <img src={noImage} width={150} height={150} className="img-fluid rounded-circle " alt="user" />
                                        }


                                    </div>
                                    <h5 className="mb-0 ">{user?.name}</h5>
                                    <p className="text-primary">245 Likes</p>
                                    <p>{user?.description}</p>
                                </div>
                                <ul className="d-flex align-items-center list-inline p-0 m-0 text-center d-none">
                                    <li className="col-md-4">
                                        <div className="iq-user mb-3">
                                            <img className="avatar-70 rounded-circle"
                                                src="/assets/images/user/04.jpg"
                                                alt="" />
                                        </div>
                                        <h5 className="mb-0 ">Terry Aki</h5>
                                        <p className="text-primary mb-0">500 Likes</p>
                                    </li>
                                    <li className="col-md-4">
                                        <div className="iq-user mb-3">
                                            <img className="avatar-70 rounded-circle"
                                                src="/assets/images/user/05.jpg"
                                                alt="" />
                                        </div>
                                        <h5 className="mb-0 ">Alex john</h5>
                                        <p className="text-primary mb-0">302 Likes</p>
                                    </li>
                                    <li className="col-md-4">
                                        <div className="iq-user mb-3">
                                            <img className="avatar-70 rounded-circle"
                                                src="/assets/images/user/07.jpg"
                                                alt="" />
                                        </div>
                                        <h5 className="mb-0 ">Rock lai</h5>
                                        <p className="text-primary mb-0">60 Likes</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="iq-card iq-card-block iq-card-stretch">
                                    <div className="iq-card-body">
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <div className="ml-2">
                                                <h5 className="mb-1">Total
                                                    <br />
                                                    Visitors</h5>
                                                <span
                                                    className="h4 text-dark mb-0 counter d-inline-block w-100">68,586
                                                </span>
                                            </div>
                                            <div
                                                className="icon iq-icon-box bg-primary rounded-circle m-0 dark-icon"
                                                data-wow-delay="0.2s">
                                                <i className="ri-user-fill" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="service-chart-01" />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="iq-card iq-card-block iq-card-stretch">
                                    <div className="iq-card-body">
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <div className="ml-2">
                                                <h5 className="mb-1">Total
                                                    <br />

                                                    Users</h5>
                                                <span
                                                    className="h4 text-dark mb-0 counter d-inline-block w-100">48,625</span>
                                            </div>
                                            <div className="icon iq-icon-box bg-success rounded-circle m-0"
                                                data-wow-delay="0.2s">
                                                <i className="ri-user-fill" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="service-chart-02" />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="iq-card iq-card-block iq-card-stretch">
                                    <div className="iq-card-body">
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <div className="ml-2">
                                                <h5 className="mb-1">Total
                                                    <br />
                                                    Hire</h5>
                                                <span
                                                    className="h4 text-dark mb-0 counter d-inline-block w-100">35,859
                                                </span>
                                            </div>
                                            <div className="icon iq-icon-box bg-danger rounded-circle m-0"
                                                data-wow-delay="0.2s">
                                                <i className="ri-user-fill" />
                                            </div>
                                        </div>
                                    </div>
                                    <div id="service-chart-03" />
                                </div>
                            </div>
                            <div className="col-lg-6 d-none">
                                <div className="iq-card iq-card-block iq-card-stretch mb-3">
                                    <div className="iq-card-body p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="user-img img-fluid">
                                                <img src="/assets/images/user/01.jpg" alt="story-img"
                                                    className="img-fluid rounded-circle avatar-40" />
                                            </div>
                                            <div className="media-support-info ml-3">
                                                <h6>Pete Sariya</h6>
                                                <p className="mb-0 font-size-12">24 jan, 2020</p>
                                            </div>
                                            <div className="iq-card-header-toolbar">
                                                <h6 className="text-dark">80.5%</h6>
                                                <div className="badge badge-pill badge-primary dark-icon">157</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="iq-card iq-card-block iq-card-stretch mb-3">
                                    <div className="iq-card-body p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="user-img img-fluid">
                                                <img src="/assets/images/user/02.jpg" alt="story-img"
                                                    className="img-fluid rounded-circle avatar-40" />
                                            </div>
                                            <div className="media-support-info ml-3">
                                                <h6>Anna Mull</h6>
                                                <p className="mb-0 font-size-12">15 feb, 2020</p>
                                            </div>
                                            <div className="iq-card-header-toolbar">
                                                <h6 className="text-dark">80.5%</h6>
                                                <div className="badge badge-pill badge-success">280</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="iq-card iq-card-block iq-card-stretch mb-3">
                                    <div className="iq-card-body p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="user-img img-fluid">
                                                <img src="/assets/images/user/03.jpg" alt="story-img"
                                                    className="img-fluid rounded-circle avatar-40" />
                                            </div>
                                            <div className="media-support-info ml-3">
                                                <h6>Alex john</h6>
                                                <p className="mb-0 font-size-12">05 March, 2020</p>
                                            </div>
                                            <div className="iq-card-header-toolbar">
                                                <h6 className="text-dark">80.5%</h6>
                                                <div className="badge badge-pill badge-danger">200</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="iq-card iq-card-block iq-card-stretch mb-3">
                                    <div className="iq-card-body p-3">
                                        <div className="d-flex align-items-center">
                                            <div className="user-img img-fluid">
                                                <img src="/assets/images/user/04.jpg" alt="story-img"
                                                    className="img-fluid rounded-circle avatar-40" />
                                            </div>
                                            <div className="media-support-info ml-3">
                                                <h6>Cliff Hanger</h6>
                                                <p className="mb-0 font-size-12">10 April, 2020</p>
                                            </div>
                                            <div className="iq-card-header-toolbar">
                                                <h6 className="text-dark">80.5%</h6>
                                                <div className="badge badge-pill badge-info">150</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-none">
                                <div className="iq-card iq-card-block iq-card-stretch">
                                    <div className="iq-card-body">
                                        <div className="d-flex align-items-center">
                                            <div
                                                className="icon iq-icon-box rounded dark-icon-light iq-bg-primary mr-3">
                                                <i className="ri-home-8-line" />
                                            </div>
                                            <div className="iq-details col-sm-9 p-0">
                                                <div
                                                    className="d-flex align-items-center justify-content-between">
                                                    <span className="title text-dark">House, bills</span>
                                                    <div className="text-primary "><b><span>$</span> 230 </b>
                                                    </div>
                                                </div>
                                                <span className>38 Transaction</span>
                                                <div className="iq-progress-bar-linear d-inline-block w-100">
                                                    <div className="iq-progress-bar">
                                                        <span className="bg-primary" data-percent={32} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mt-2 mb-2" />
                                        <div className="d-flex align-items-center">
                                            <div className="icon iq-icon-box rounded iq-bg-success mr-3">
                                                <i className="ri-camera-lens-line" />
                                            </div>
                                            <div className="iq-details col-sm-9 p-0">
                                                <div
                                                    className="d-flex align-items-center justify-content-between">
                                                    <span className="title text-dark">Children</span>
                                                    <div className="text-success"><b><span>$</span> 120 </b>
                                                    </div>
                                                </div>
                                                <span className>9 Transaction</span>
                                                <div className="iq-progress-bar-linear d-inline-block w-100">
                                                    <div className="iq-progress-bar">
                                                        <span className="bg-success" data-percent={20} />
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center justify-content-between">
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mt-2 mb-2" />
                                        <div className="d-flex align-items-center">
                                            <div className="icon iq-icon-box rounded iq-bg-info mr-3">
                                                <i className="ri-bank-line" />
                                            </div>
                                            <div className="iq-details col-sm-9 p-0">
                                                <div
                                                    className="d-flex align-items-center justify-content-between">
                                                    <span className="title text-dark">Banking</span>
                                                    <div className="text-info"><b><span>$</span> 110 </b></div>
                                                </div>
                                                <span className>50 Transaction</span>
                                                <div className="iq-progress-bar-linear d-inline-block w-100">
                                                    <div className="iq-progress-bar">
                                                        <span className="bg-info" data-percent={60} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mt-2 mb-2" />
                                        <div className="d-flex align-items-center">
                                            <div className="icon iq-icon-box rounded iq-bg-info mr-3">
                                                <i className="ri-bank-line" />
                                            </div>
                                            <div className="iq-details col-sm-9 p-0">
                                                <div
                                                    className="d-flex align-items-center justify-content-between">
                                                    <span className="title text-dark">Office</span>
                                                    <div className="text-info"><b><span>$</span> 310 </b></div>
                                                </div>
                                                <span className>50 Expenses</span>
                                                <div className="iq-progress-bar-linear d-inline-block w-100">
                                                    <div className="iq-progress-bar">
                                                        <span className="bg-info" data-percent={70} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 d-none">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                            <div className="iq-card-header d-flex justify-content-between border-bottom">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Activity Details</h4>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <ul className="iq-timeline">
                                    <li>
                                        <div className="timeline-dots" />
                                        <h6 className="float-left mb-1">Jan 15</h6>
                                        <div className="d-inline-block w-100">
                                            <p className="mb-0">New Event Created</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-dots" />
                                        <h6 className="float-left mb-1">Jan 22</h6>
                                        <div className="d-inline-block w-100">
                                            <p className="mb-0">New updates "Prepared"</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-dots" />
                                        <h6 className="float-left mb-1">Feb 09</h6>
                                        <div className="d-inline-block w-100">
                                            <p className="mb-0">2019 Reports</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-dots" />
                                        <h6 className="float-left mb-1">march 05</h6>
                                        <div className="d-inline-block w-100">
                                            <p className="mb-0">New Item</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-dots" />
                                        <h6 className="float-left mb-1">April 17</h6>
                                        <div className="d-inline-block w-100">
                                            <p className="mb-0">New Event</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="timeline-dots" />
                                        <h6 className="float-left mb-1">May 25</h6>
                                        <div className="d-inline-block w-100">
                                            <p className="mb-0">New Event</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 d-none">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                            <div className="iq-card-header d-flex justify-content-between border-none">
                                <div className="iq-header-title">
                                    <h5 className="card-title">Load time by country</h5>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div id="world-map" style={{ height: '200px', position: 'relative' }} />
                                <div className="table-responsive mt-3">
                                    <table className="table mb-0 table-borderless">
                                        <thead>
                                            <tr>
                                                <th scope="col">Location</th>
                                                <th scope="col">Order No</th>
                                                <th scope="col">Review</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Australia</td>
                                                <td>#1234</td>
                                                <td>3.5<i className="ri-star-fill text-warning pl-2" /></td>
                                            </tr>
                                            <tr>
                                                <td>United States</td>
                                                <td>#5647</td>
                                                <td>4.5<i className="ri-star-fill text-warning pl-2" /></td>
                                            </tr>
                                            <tr>
                                                <td>UK</td>
                                                <td>#8741</td>
                                                <td>5.5<i className="ri-star-fill text-warning pl-2" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 d-none">
                        <div className="iq-card iq-card-block iq-card-stretch mb-3">
                            <div className="iq-card-body p-3">
                                <div className="d-flex align-items-center">
                                    <div className="user-img img-fluid">
                                        <img src="/assets/images/user/01.jpg" alt="story-img"
                                            className="img-fluid rounded-circle avatar-40" />
                                    </div>
                                    <div className="media-support-info ml-3">
                                        <h6>Pete Sariya</h6>
                                        <p className="mb-0 font-size-12">24 jan, 2020</p>
                                    </div>
                                    <div className="iq-card-header-toolbar">
                                        <h6 className="text-dark">80.5%</h6>
                                        <div className="badge badge-pill badge-primary dark-icon">157</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="iq-card iq-card-block iq-card-stretch mb-3">
                            <div className="iq-card-body p-3">
                                <div className="d-flex align-items-center">
                                    <div className="user-img img-fluid">
                                        <img src="/assets/images/user/02.jpg" alt="story-img"
                                            className="img-fluid rounded-circle avatar-40" />
                                    </div>
                                    <div className="media-support-info ml-3">
                                        <h6>Anna Mull</h6>
                                        <p className="mb-0 font-size-12">15 feb, 2020</p>
                                    </div>
                                    <div className="iq-card-header-toolbar">
                                        <h6 className="text-dark">80.5%</h6>
                                        <div className="badge badge-pill badge-success">280</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="iq-card iq-card-block iq-card-stretch mb-3">
                            <div className="iq-card-body p-3">
                                <div className="d-flex align-items-center">
                                    <div className="user-img img-fluid">
                                        <img src="/assets/images/user/03.jpg" alt="story-img"
                                            className="img-fluid rounded-circle avatar-40" />
                                    </div>
                                    <div className="media-support-info ml-3">
                                        <h6>Alex john</h6>
                                        <p className="mb-0 font-size-12">05 March, 2020</p>
                                    </div>
                                    <div className="iq-card-header-toolbar">
                                        <h6 className="text-dark">80.5%</h6>
                                        <div className="badge badge-pill badge-danger">200</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="iq-card iq-card-block iq-card-stretch mb-3">
                            <div className="iq-card-body p-3">
                                <div className="d-flex align-items-center">
                                    <div className="user-img img-fluid">
                                        <img src="/assets/images/user/04.jpg" alt="story-img"
                                            className="img-fluid rounded-circle avatar-40" />
                                    </div>
                                    <div className="media-support-info ml-3">
                                        <h6>Cliff Hanger</h6>
                                        <p className="mb-0 font-size-12">10 April, 2020</p>
                                    </div>
                                    <div className="iq-card-header-toolbar">
                                        <h6 className="text-dark">80.5%</h6>
                                        <div className="badge badge-pill badge-info">150</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="iq-card iq-card-block iq-card-stretch">
                            <div className="iq-card-body p-3">
                                <div className="d-flex align-items-center">
                                    <div className="user-img img-fluid">
                                        <img src="/assets/images/user/05.jpg" alt="story-img"
                                            className="img-fluid rounded-circle avatar-40" />
                                    </div>
                                    <div className="media-support-info ml-3">
                                        <h6>Joe Shestak</h6>
                                        <p className="mb-0 font-size-12">25 may, 2020</p>
                                    </div>
                                    <div className="iq-card-header-toolbar">
                                        <h6 className="text-dark">80.5%</h6>
                                        <div className="badge badge-pill badge-dark ">150</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 d-none">
                        <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                            <div className="iq-card-header d-flex justify-content-between">
                                <div className="iq-header-title">
                                    <h4 className="card-title">Project Tests</h4>
                                </div>
                                <div className="iq-card-header-toolbar d-flex align-items-center">
                                    <div className="dropdown">
                                        <span className="dropdown-toggle dropdown-bg dark-icon-light iq-bg-primary"
                                            id="dropdownMenuButton2" data-toggle="dropdown" aria-expanded="false" role="button">
                                            view All<i className="ri-arrow-down-s-line ml-1 text-primary" />
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right shadow-none"
                                            aria-labelledby="dropdownMenuButton2" style={{
                                                position: 'absolute',
                                                willChange: 'transform',
                                                top: '0px',
                                                left: '0px',
                                                transform: 'translate3d(391px, 30px, 0px)'
                                            }}>
                                            <a className="dropdown-item" href="javascript:void(0)"><i
                                                className="ri-eye-fill mr-2" />View</a>
                                            <a className="dropdown-item" href="javascript:void(0)"><i
                                                className="ri-delete-bin-6-fill mr-2" />Delete</a>
                                            <a className="dropdown-item" href="javascript:void(0)"><i
                                                className="ri-pencil-fill mr-2" />Edit</a>
                                            <a className="dropdown-item" href="javascript:void(0)"><i
                                                className="ri-printer-fill mr-2" />Print</a>
                                            <a className="dropdown-item" href="javascript:void(0)"><i
                                                className="ri-file-download-fill mr-2" />Download</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="iq-card-body">
                                <div className="table-responsive">
                                    <table className="table mb-0 mt-2 table-borderless table-test">
                                        <tbody>
                                            <tr>
                                                <td className="text-center">
                                                    <img className="rounded-circle img-fluid avatar-40"
                                                        src="/assets/images/user/01.jpg" alt="profile" />
                                                </td>
                                                <td>
                                                    <h6>Retail Int</h6>
                                                    <p className="mb-0">Lorem Ipsum dummy</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2020/03/20</p>
                                                    <span className="text-primary">Enter Project</span>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2020/03/29</p>
                                                    <span className="text-primary">Last Modified</span>
                                                </td>
                                                <td>
                                                    <p className="mb-2">150</p>
                                                    <div className="iq-progress-bar">
                                                        <span className="bg-primary" data-percent={70} />
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    <div className="text-danger"><i className="ri-alert-fill" />
                                                    </div>
                                                    <span className="text-danger">Test Inomplete</span>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <a href="javascript:void(0);"
                                                            className="avatar-45 text-center ml-3 rounded-circle iq-bg-success font-size-18"><i
                                                                className="ri-pencil-fill font-size-16" /></a>
                                                        <a href="javascript:void(0);"
                                                            className="avatar-45 rounded-circle text-center ml-3 iq-bg-danger font-size-18"><i
                                                                className="ri-delete-bin-line font-size-16" /></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">
                                                    <img className="rounded-circle img-fluid avatar-40"
                                                        src="/assets/images/user/01.jpg" alt="profile" />
                                                </td>
                                                <td>
                                                    <h6>Retail Int</h6>
                                                    <p className="mb-0">Lorem Ipsum dummy</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2020/03/20</p>
                                                    <span className="text-primary">Enter Project</span>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2020/03/29</p>
                                                    <span className="text-primary">Last Modified</span>
                                                </td>
                                                <td>
                                                    <p className="mb-2">130</p>
                                                    <div className="iq-progress-bar">
                                                        <span className="bg-primary" data-percent={70} />
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    <div className="text-success">0/3</div>
                                                    <p className="mb-0">Active Runs</p>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <a href="javascript:void(0);"
                                                            className="avatar-45 text-center ml-3 rounded-circle iq-bg-success font-size-18"><i
                                                                className="ri-pencil-fill font-size-16" /></a>
                                                        <a href="javascript:void(0);"
                                                            className="avatar-45 rounded-circle text-center ml-3 iq-bg-danger font-size-18"><i
                                                                className="ri-delete-bin-line font-size-16" /></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">
                                                    <img className="rounded-circle img-fluid avatar-40"
                                                        src="/assets/images/user/01.jpg" alt="profile" />
                                                </td>
                                                <td>
                                                    <h6>Retail Int</h6>
                                                    <p className="mb-0">Lorem Ipsum dummy</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2020/03/20</p>
                                                    <span className="text-primary">Enter Project</span>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2020/03/29</p>
                                                    <span className="text-primary">Last Modified</span>
                                                </td>
                                                <td>
                                                    <p className="mb-2">135</p>
                                                    <div className="iq-progress-bar">
                                                        <span className="bg-primary" data-percent={70} />
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    <div className="text-danger"><i className="ri-alert-fill" />
                                                    </div>
                                                    <span className="text-danger">Test Inomplete</span>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <a href="javascript:void(0);"
                                                            className="avatar-45 text-center ml-3 rounded-circle iq-bg-success font-size-18"><i
                                                                className="ri-pencil-fill font-size-16" /></a>
                                                        <a href="javascript:void(0);"
                                                            className="avatar-45 rounded-circle text-center ml-3 iq-bg-danger font-size-18"><i
                                                                className="ri-delete-bin-line font-size-16" /></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">
                                                    <img className="rounded-circle img-fluid avatar-40"
                                                        src="/assets/images/user/01.jpg" alt="profile" />
                                                </td>
                                                <td>
                                                    <h6>Retail Int</h6>
                                                    <p className="mb-0">Lorem Ipsum dummy</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2020/03/20</p>
                                                    <span className="text-primary">Enter Project</span>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2020/03/29</p>
                                                    <span className="text-primary">Last Modified</span>
                                                </td>
                                                <td>
                                                    <p className="mb-2">142</p>
                                                    <div className="iq-progress-bar">
                                                        <span className="bg-primary" data-percent={70} />
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    <div className="text-success">0/3</div>
                                                    <p className="mb-0">Active Runs</p>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <a href="javascript:void(0);"
                                                            className="avatar-45 text-center ml-3 rounded-circle iq-bg-success font-size-18"><i
                                                                className="ri-pencil-fill font-size-16" /></a>
                                                        <a href="javascript:void(0);"
                                                            className="avatar-45 rounded-circle text-center ml-3 iq-bg-danger font-size-18"><i
                                                                className="ri-delete-bin-line font-size-16" /></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="text-center">
                                                    <img className="rounded-circle img-fluid avatar-40"
                                                        src="/assets/images/user/01.jpg" alt="profile" />
                                                </td>
                                                <td>
                                                    <h6>Retail Int</h6>
                                                    <p className="mb-0">Lorem Ipsum dummy</p>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2020/03/20</p>
                                                    <span className="text-primary">Enter Project</span>
                                                </td>
                                                <td>
                                                    <p className="mb-0">2020/03/29</p>
                                                    <span className="text-primary">Last Modified</span>
                                                </td>
                                                <td>
                                                    <p className="mb-2">160</p>
                                                    <div className="iq-progress-bar">
                                                        <span className="bg-primary" data-percent={80} />
                                                    </div>
                                                </td>
                                                <td className="text-right">
                                                    <div className="text-success">0/3</div>
                                                    <p className="mb-0">Active Runs</p>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center justify-content-end">
                                                        <a href="javascript:void(0);"
                                                            className="avatar-45 text-center ml-3 rounded-circle iq-bg-success font-size-18"><i
                                                                className="ri-pencil-fill font-size-16" /></a>
                                                        <a href="javascript:void(0);"
                                                            className="avatar-45 rounded-circle text-center ml-3 iq-bg-danger font-size-18"><i
                                                                className="ri-delete-bin-line font-size-16" /></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Dashboard;