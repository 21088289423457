import React, { useEffect, useState } from 'react';
import { getInterViewKanban, selectedAgent } from "../../constants/adminWebServices";
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'datatables.net-buttons-bs4';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import 'datatables.net-buttons/js/buttons.html5.js';
import $ from 'jquery';
import {
    allStatusRequest,
    agentStatusChangeRequest,
    allInterviewRequest
} from "../../sevices/interview/interviewService";
import moment from "moment";
import { createRoot } from "react-dom/client";
import { sweetAlert } from "../../lib/sweetAlert";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const UseInterview = () => {
    const [filterInput, setFilterInput] = useState('');

    const [interviews, setSetInterviews] = useState([])
    let token = localStorage.getItem('access_token');
    const [statuses, setStatuses] = useState([]);
    const [status, setStatus] = useState('');
    const [reqStatus, setReqStatus] = useState(null);
    const [reqId, setReqId] = useState(null);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        getTableData();
        getData();
    }, []);

    const getData = async () => {
        let res = await allStatusRequest(token);
        if (res?.status === 'success') {
            setStatuses(res?.data);
        }
    };

    const getTableData = async (hasStatus = null, page) => {
        setLoading(true);
        console.log('page', page, 'per', perPage, 's', (page - 1) * perPage)
        try {
            const body = {
                start: page === undefined ? 0 : (page - 1) * perPage,
                length: 10,
            }
            const data = await allInterviewRequest(token, body)
            console.log('data', data)

            setSetInterviews(data.data);
            setTotalRows(data.recordsTotal);
            setLoading(false);

        } catch (e) {
            console.log('e', e.message)
            setLoading(false);
        }
        /*$('#crmTable').DataTable({
            "serverSide": true,
            "processing": true,
            "bInfo": true,
            "bPaginate": true,
            "bLengthChange":false,
            'pageLength':10,
            "ajax":{
                "url": selectedAgent,
                "data":function(data){
                    data.status = hasStatus;
                },
                'type': 'POST',
                'beforeSend': function (request) {
                    request.setRequestHeader("Authorization", "Bearer "+token);
                }
            },
            "columns":[
                {
                    data: 'station_name',
                    'render': function(data, type, row, meta) {
                        return row?.get_agent?.station_name;
                    }
                },
                {
                    data: 'station_phone',
                    'render': function(data, type, row, meta) {
                        return row?.get_agent?.station_phone;
                    }
                },
                {
                    data: 'city',
                    'render': function(data, type, row, meta) {
                        return row?.get_agent?.city;
                    }
                },
                {
                    data: 'state',
                    'render': function(data, type, row, meta) {
                        return row?.get_agent?.state;
                    }
                },
                {
                    data: 'street_address',
                    'render': function(data, type, row, meta) {
                        return row?.get_agent?.street_address;
                    }
                },
                {
                    data: 'status_name',
                    'targets' : 0,
                    createdCell: (data, type, row, meta) => {
                        createRoot(data).render(
                            <>
                                <button onClick={() => handleShow(row?.id,row?.status)} type="button" className="btn btn-sm text-white" style={{backgroundColor:row?.status_color}}>
                                    {row?.status_name}
                                </button>
                            </>
                        );
                    }
                },
                {
                    data: 'created_at',
                    'render': function(data, type, row, meta) {
                        return moment(row?.get_agent?.created_at).format('DD-MMMM-YYYY h:mm A');
                    }
                },
            ],
            "bDestroy": true,
            "dom": 'Blfrtip',
            "buttons": [
                'pdf','csv'
            ],
        });*/
    };

    const handleStatus = (e) => {
        setStatus(e.target.value);
        getTableData(e.target.value);
    };

    const reqHandleStatus = (e) => {
        setReqStatus(e.target.value)
    };

    const changeStatus = async () => {
        if (reqStatus && reqId) {
            let body = {
                interview_id: reqId,
                status: reqStatus,
            };
            let res = await agentStatusChangeRequest(body, token);
            if (res.status === 'success') {
                await sweetAlert(res.msg, res.status);
                handleClose();
                getTableData();
            } else {
                await sweetAlert(res.msg, res.status);
            }
        } else {
            await sweetAlert('*There is something wrong.', 'warning');
        }
    };

    // const allInterviewRequest = async () => {
    //     let res = await fetch(getInterViewKanban, {
    //         method: "GET",
    //         headers: new Headers({
    //             'content-type': 'application/json',
    //             'accept': 'application/json',
    //             'Authorization': 'Bearer ' + token
    //         }),
    //         // body: JSON.stringify(body),
    //     });
    //     return await res.json();
    // };



    const handleClose = () => {
        setReqId(null);
        setReqStatus(null);
        setShow(false);
    };

    const handleShow = (id, currentStatus) => {
        setReqId(id);
        setReqStatus(currentStatus);
        setShow(true);
    };

    const handlePageChange = (page) => {
        getTableData(null, page)
    };

    const columns = [
        {
            name: 'Name',
            selector: (row) => row?.get_agent?.name,
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (row) => row?.get_agent?.phone,
            sortable: true,
        },
        {
            name: 'City',
            selector: (row) => row?.get_agent?.city,
            sortable: true,
        },
        {
            name: 'State',
            selector: (row) => row?.get_agent?.state,
            sortable: true,
        },
        {
            name: 'Address',
            selector: (row) => row?.get_agent?.address,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => {
                return (
                    <>
                        <button onClick={() => handleShow(row?.id, row?.status)} type="button"
                            className="btn btn-sm text-white" style={{ backgroundColor: row?.status_color }}>
                            {row?.status_name}
                        </button>
                    </>
                )
            },
            sortable: true,
        },
        {
            name: 'selected Date',
            selector: (row) => moment(row?.get_agent?.created_at).format('DD-MMMM-YYYY h:mm A'),
            sortable: true,
        },
    ]

    const handleFilter = (e) => {
        setFilterInput(e.target.value)
    }

    const filtered = interviews?.map(
        (item) => {
            return { ...item?.get_agent, status: item?.status_name }
        }
    );


    const filteredItems = interviews?.filter(
        (item) =>
            item?.status_name && item?.status_name.toLowerCase().includes(filterInput?.toLowerCase())
    );

    return [
        statuses,
        status,
        reqStatus,
        show,
        handleStatus,
        reqHandleStatus,
        handleClose,
        changeStatus,
        columns,
        filteredItems,
        interviews,
        loading,
        totalRows,
        handlePageChange,
        handleFilter,
        filtered,
    ];
};

export default UseInterview;