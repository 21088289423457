import React, { Fragment } from 'react';
import { Routes, Route } from "react-router-dom";

import './App.css';
import Master from "./components/layout/Master";
import Dashboard from "./pages/dashboard";
import ProfileEdit from "./pages/profile/edit";
import ProfileDetail from "./pages/profile/detail";
import Login from "./pages/auth/login";
import UseAuth from "./hooks/auth/UseAuth";
import Agent from "./pages/agent";
import Agency from "./pages/agency";
import AddEditAgency from "./pages/agency/addEdit";
import SignUp from "./pages/auth/signUp";
import Interview from "./pages/interview";
import GettingStarted from "./pages/started";
import AgentDetail from "./pages/agent/detail";
import AddEditAgent from "./pages/agent/addEdit";
import SelectOtpOption from './pages/auth/selectOtpOption';
import SendOtpScreen from './pages/auth/sentOtp';
import TwoFactorCode from './pages/twoFactor/twoFactorCode';
import InterviewKanban from './pages/interview/InterviewKanban';
import ViewProfile from './pages/profile/viewProfile';

function App() {

    const [
        // isSignedIn,
    ] = UseAuth();

    return (
        <Fragment>
            <Master>
                <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route exact path="/getting-started" element={<GettingStarted />} />
                    <Route exact path="/users" element={<Agency />} />
                    <Route path="/add-edit-user">
                        <Route index element={<AddEditAgency />} />
                        <Route path=":id" element={<AddEditAgency />} />
                    </Route>

                    <Route exact path="/agents" element={<Agent />} />
                    <Route exact path="/agent-detail/:id" element={<AgentDetail />} />
                    <Route exact path="/add-edit-agent/:id" element={<AddEditAgent />} />
                    <Route exact path="/interviews" element={<Interview />} />
                    <Route exact path="/profile-edit" element={<ProfileEdit />} />
                    <Route exact path="/profile" element={<ViewProfile />} />
                    <Route exact path="/two-factor-code" element={<TwoFactorCode />} />
                    <Route exact path="/interview-kanban" element={<InterviewKanban />} />

                    <Route exact path="/auth/login" element={<Login />} />
                    <Route exact path="/auth/select-option" element={<SelectOtpOption />} />
                    <Route exact path="/auth/send-otp" element={<SendOtpScreen />} />
                    <Route exact path="/auth/sign-up/:id" element={<SignUp />} />
                </Routes>
            </Master>
        </Fragment>
    );
}

export default App;
