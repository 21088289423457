import React, { Fragment, useState } from 'react';
import UseLogin from "../../hooks/auth/UseLogin";
import { matchOtpRequest, sendOtpRequest } from "../../sevices/auth/loginService";
import { sweetAlert } from "../../lib/sweetAlert";
import { useNavigate } from "react-router-dom";
import { setIsSignedIn, setUserData } from "../../features/adminSlice";
import { useDispatch } from "react-redux";

const SendOtpScreen = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [otp, setOtp] = useState('')


    const handleOtpVerification = async (event) => {
        event.preventDefault();
        try {
            let token = localStorage.getItem("access_token");
            const create = await matchOtpRequest(token, otp);
            if (create?.status === "success") {
                await sweetAlert('Login Successfully', create.status);
                dispatch(setIsSignedIn(true));
                navigate('/')
            } else {
                await sweetAlert('Something Went Wrong', create.status);
            }
        } catch (e) {
            console.log(e.messaage);
        }
    }

    return (
        <Fragment>
            <section className="sign-in-page">
                <div className="container bg-white mt-5 p-0">
                    <div className="row no-gutters">
                        <div className="col-sm-6 align-self-center">
                            <div className="sign-in-from">
                                <h1 className="mb-0 dark-signin">OTP Code</h1>
                                <p>Enter your OTP Code.</p>
                                <div className="mt-4">
                                    <form onSubmit={handleOtpVerification}>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1">OTP</label>
                                            <input
                                                type="text"
                                                className="form-control mb-0"
                                                id="exampleInputEmail1"
                                                placeholder="Enter OTP Code"
                                                value={otp}
                                                onChange={(e) => setOtp(e.target.value)}
                                            />
                                        </div>
                                        <div className="d-inline-block w-100">
                                            <button type="submit" className="btn btn-primary float-right">Submit</button>
                                        </div>
                                    </form>
                                    <div className="sign-info">
                                        <ul className="iq-social-media">
                                            <li><a href="javascript:void(0)"><i className="ri-facebook-box-line" /></a></li>
                                            <li><a href="javascript:void(0)"><i className="ri-twitter-line" /></a></li>
                                            <li><a href="javascript:void(0)"><i className="ri-instagram-line" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 text-center">
                            <div className="sign-in-detail text-white">
                                <a className="sign-in-logo mb-5" href="javascript:void(0)">
                                    <h1 className="text-white">IBROKER</h1>
                                </a>
                                <div className="slick-slider11" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items={1} data-items-laptop={1} data-items-tab={1} data-items-mobile={1} data-items-mobile-sm={1} data-margin={0}>
                                    <div className="item">
                                        <img src="/assets/images/login/1.jpg" className="img-fluid mb-4" alt="logo" />
                                        <h4 className="mb-1 text-white">Manage your orders</h4>
                                        <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default SendOtpScreen;