let token = localStorage.getItem("access_token");

export const postAuthData = async (url, data, header = true) => {
    try {
        let apiReq = {
            method: 'POST',
            body: data,
        }
        if (header) {
            apiReq.headers = new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            });
        }
        else {
            apiReq.headers = new Headers({
                'Authorization': `Bearer ${token}`
            });

        }
        const res = await fetch(url, apiReq);
        return await res.json()
    } catch (error) {
        console.log(error)
    }
}