import React, {Fragment} from 'react';

const Footer = () => {
    return (
        <Fragment>
            {/* Footer */}
            <footer className="iq-footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item"><a href="privacy-policy.html">Privacy Policy</a></li>
                                <li className="list-inline-item"><a href="terms-of-service.html">Terms of Use</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-6 text-right">
                            © Copyright Insurance {new Date().getFullYear()}. Made with by <a href="https://technottix.com/">Technottix</a>.
                        </div>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
};

export default Footer;