import React,{Fragment} from 'react';
import UseLogin from "../../hooks/auth/UseLogin";

const SignUp = () => {

    const [
        name,
        email,
        password,
        error,
        handleName,
        handleEmail,
        handlePassword,
        login,
        signUp,
        shiftLogin,
    ] = UseLogin();

    return (
        <Fragment>
            <section className="sign-in-page">
                <div className="container bg-white mt-5 p-0">
                    <div className="row no-gutters">
                        <div className="col-sm-6 align-self-center">
                            <div className="sign-in-from">
                                <h1 className="mb-0">Sign Up</h1>
                                <p>Enter your name, email address and password to access portal.</p>
                                {(error.msg) && <span className="text-danger">{error.msg}</span>}
                                <div className="mt-4">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Your Full Name</label>
                                        <input type="text" className="form-control mb-0"
                                               id="exampleInputEmail1"
                                               onChange={handleName}
                                               value={name}
                                               placeholder="Your Full Name" />
                                        {(error.name) && <span className="text-danger">{error.name}</span>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail2">Email address</label>
                                        <input type="email" className="form-control mb-0"
                                               id="exampleInputEmail2"
                                               onChange={handleEmail}
                                               value={email}
                                               placeholder="Enter email" />
                                        {(error.email) && <span className="text-danger">{error.email}</span>}
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1">Password</label>
                                        <input type="password" className="form-control mb-0"
                                               id="exampleInputPassword1"
                                               onChange={handlePassword}
                                               value={password}
                                               placeholder="Password" />
                                        {(error.password) && <span className="text-danger">{error.password}</span>}
                                    </div>
                                    <div className="d-inline-block w-100">
                                        <div className="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                                            <input type="checkbox" className="custom-control-input"
                                                   id="customCheck1" />
                                            <label className="custom-control-label" htmlFor="customCheck1">I accept
                                                <a href="javascript:void(0)"> Terms and Conditions</a>
                                            </label>
                                        </div>
                                        <button onClick={signUp} className="btn btn-primary float-right">Sign Up</button>
                                    </div>
                                    <div className="sign-info">
                                        <span className="dark-color d-inline-block line-height-2">Already Have Account ?
                                            <a onClick={shiftLogin} href="javascript:void(0)">Log In</a>
                                        </span>
                                        <ul className="iq-social-media">
                                            <li><a href="javascript:void(0)"><i className="ri-facebook-box-line" /></a></li>
                                            <li><a href="javascript:void(0)"><i className="ri-twitter-line" /></a></li>
                                            <li><a href="javascript:void(0)"><i className="ri-instagram-line" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 text-center">
                            <div className="sign-in-detail text-white">
                                <a className="sign-in-logo mb-5" href="javascript:void(0)">
                                    <h1 className="text-white">IBROKER</h1>
                                    {/*<img src="/assets/images/logo-white.png" className="img-fluid" alt="logo" />*/}
                                </a>
                                <div className="slick-slider11" data-autoplay="true" data-loop="true" data-nav="false" data-dots="true" data-items={1} data-items-laptop={1} data-items-tab={1} data-items-mobile={1} data-items-mobile-sm={1} data-margin={0}>
                                    <div className="item">
                                        <img src="/assets/images/login/1.png" className="img-fluid mb-4" alt="logo" />
                                        <h4 className="mb-1 text-white">Manage your orders</h4>
                                        <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default SignUp;