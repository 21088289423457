import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FaThList } from "react-icons/fa";
import { v4 as uuid } from "uuid";
import { BsFillGrid1X2Fill, BsListNested, BsPaperclip } from "react-icons/bs";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getInterViewKanban } from '../../constants/adminWebServices';
import { agentStatusChangeRequest, getAllKanaban } from '../../sevices/interview/interviewService';
import moment from "moment";
import { sweetAlert } from '../../lib/sweetAlert';


const tasks = [
    {
        id: "1",
        name: "Harry Brook",
        phone: "+920305447894",
        city: "Karachi",
        country: "pakistan",
        state: "OH",
        selectedDate: "30-10-23",
        address: "street 360 New York"

    },
    {
        id: "2",
        name: "David Warner",
        phone: "+920305447894",
        city: "Karachi",
        country: "pakistan",
        state: "OH",
        selectedDate: "30-10-23",
        address: "garden east"

    },
    {
        id: "3",
        name: "Ali Ahmed",
        phone: "+920305447894",
        city: "Karachi",
        country: "pakistan",
        state: "OH",
        selectedDate: "30-10-23",
        address: "gulshan iqbal 12 d"

    },
    {
        id: "4",
        name: "Iftikhar Ahmed",
        phone: "+923054478941",
        city: "Karachi",
        country: "pakistan",
        state: "OH",
        selectedDate: "30-10-23",
        address: "street 360 New York"
    },
    {
        id: "5",
        name: "Shahab",
        phone: "+920305478941",
        city: "Karachi",
        country: "pakistan",
        state: "OH",
        selectedDate: "30-10-23",
        address: "Nazimabad Karachi"

    }
];

const taskStatus = {
    New: {
        name: "New",
        items: tasks,
        color: "red",
    },
    awaitingCall: {
        name: "Awaiting Call",
        items: [],
        color: "blue",

    },
    interviewSchedule: {
        name: "Interview Schedule",
        items: [],
        color: "green",

    },
    inReview: {
        name: "In Review",
        items: [],
        color: "yellow",

    },
    hred: {
        name: "Hired",
        items: [],
        color: "orange",

    },
    rejected: {
        name: "Rejected",
        items: [],
        color: "purple",

    }
};

const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
        const sourceItems = [...sourceColumn.items];
        const destItems = [...destColumn.items];
        const [removed] = sourceItems.splice(source.index, 1);
        destItems.splice(destination.index, 0, removed);
        setColumns({
            ...columns,
            [source.droppableId]: {
                ...sourceColumn,
                items: sourceItems
            },
            [destination.droppableId]: {
                ...destColumn,
                items: destItems
            }
        });
    } else {
        const column = columns[source.droppableId];
        const copiedItems = [...column.items];
        const [removed] = copiedItems.splice(source.index, 1);
        copiedItems.splice(destination.index, 0, removed);
        setColumns({
            ...columns,
            [source.droppableId]: {
                ...column,
                items: copiedItems
            }
        });
    }
};

const InterviewKanban = () => {

    let token = localStorage.getItem('access_token');



    // const allInterviewRequest = async () => {
    //     let res = await fetch(getInterViewKanban, {
    //         method: "GET",
    //         headers: new Headers({
    //             'content-type': 'application/json',
    //             'accept': 'application/json',
    //             'Authorization': 'Bearer ' + token
    //         }),
    //         // body: JSON.stringify(body),
    //     });
    //     return await res.json();
    // };

    const [columns, setColumns] = useState({});


    const getKanban = async () => {
        await getAllKanaban(token).then((res) => {
            // let object = {};
            // res?.data
            //     ?.sort((a, b) => a.order_no - b.order_no)
            //     ?.map((item, index) => {
            //         const id = item?.id;
            //         object[id] = {
            //             ...item,
            //         };
            //     });
            console.log("res-=====-==-=-=dfas=", res?.data)
            setColumns(res?.data);
        });
    };

    console.log("setColumns ============= ", columns)
    console.log("setColumns get_agent ============= ", columns?.get_agent)


    // useEffect(() => {
    //     allInterviewRequest()
    // }, [])
    useEffect(() => {
        getKanban();
    }, []);
    // const [columns, setColumns] = useState(columnsFromBackend);

    return (
        <>
            <div className="container">
                <div className="row align-items-center">
                    {/* <div className="col-md-12">
                        <h1>Interview</h1>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="status">Status</label>
                                    <select id="status" className="form-control" onChange={handleStatus} value={status}>
                                        <option value=""> -- Select -- </option>
                                        {statuses.map((i) => {
                                            return (
                                                <option value={i?.id}>{i?.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-auto pe-0">

                        <h1>Interview Kanban</h1>
                    </div>
                    <div className="col">
                        <div className="row">

                            <div className="col-auto ">
                                <Link to={"/interviews"} className='btn btn-light border p-2' style={{ lineHeight: "95%" }}>
                                    <FaThList size={16} />
                                </Link>
                            </div>
                            <div className="col-auto pl-0">
                                <Link to={""} className='btn btn-primary border p-2' style={{ lineHeight: "95%" }}>
                                    <BsFillGrid1X2Fill size={16} />
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select id="status" className="form-control" onChange={handleStatus} value={status}>
                            <option value=""> -- Select -- </option>
                            {statuses.map((i) => {
                                return (
                                    <option value={i?.id}>{i?.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div> */}
                </div>

                <div className="table-responsive">
                    <div
                        className="row g-0 mt-4 flex-nowrap"
                        style={{ gap: "0 10px" }}
                        key={1}
                    >
                        <DragDropContext
                            onDragEnd={async (result) => {
                                onDragEnd(result, columns, setColumns)
                                const obj = { status: result?.destination?.droppableId, interview_id: result?.draggableId }
                                console.log("result ==== ", result)
                                let res = await agentStatusChangeRequest(obj, token);
                                if (res.status === 'success') {
                                    sweetAlert(res.msg, res.status);
                                } else {
                                    sweetAlert(res.msg, res.status);
                                }
                            }}
                        >
                            {Object.entries(columns).map(([columnId, column], index) => {
                                return (
                                    <div
                                        // style={{
                                        //     display: "flex",
                                        //     flexDirection: "column",
                                        //     alignItems: "center"
                                        // }}
                                        style={{
                                            // backgroundColor: '#fff',
                                            border: "1px solid #e5e5e5",
                                            alignSelf: "stretch"

                                        }}
                                        className='col-md-4 rounded overflow-hidden'
                                        key={columnId}
                                    >

                                        <div
                                            className="w-100  border-bottom p-2"
                                            style={{ backgroundColor: column?.color }}
                                        >

                                            <p className="text-white text-capitalize text-center fw-600 s-16 m-0">
                                                <span>
                                                    <BsListNested size={22} className="mr-2" />
                                                </span>
                                                <span style={{ fontSize: "15px" }}>{column.name}</span>
                                                {/*<span> -10-50 Leads</span>*/}
                                            </p>
                                        </div>
                                        <div style={{ margin: 8 }}>
                                            <Droppable droppableId={`${columnId}`} key={columnId}>
                                                {(provided, snapshot) => {
                                                    return (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            style={{
                                                                // background: snapshot.isDraggingOver
                                                                //     ? "lightblue"
                                                                //     : "lightgrey",
                                                                padding: 4,
                                                                // width: 250,
                                                                minHeight: 500,


                                                            }}
                                                        >
                                                            {column?.items?.map((item, index) => {
                                                                return (
                                                                    <Draggable
                                                                        key={item.id}
                                                                        // draggableId={item.id}
                                                                        draggableId={`${item.id}`}
                                                                        index={index}
                                                                    >
                                                                        {(provided, snapshot) => {
                                                                            return (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={{
                                                                                        userSelect: "none",
                                                                                        // padding: 16,
                                                                                        margin: "0 0 8px 0",
                                                                                        minHeight: "50px",
                                                                                        // backgroundColor: snapshot.isDragging
                                                                                        //     ? "#263B4A"
                                                                                        //     : "#456C86",
                                                                                        // color: "white",
                                                                                        ...provided.draggableProps.style
                                                                                    }}
                                                                                >

                                                                                    <div className="card p-3">
                                                                                        <div className="row">

                                                                                            <div className="col-md-12">
                                                                                                <p className='text-primary mb-0 text-capitalize'>
                                                                                                    {item?.get_agent?.name}
                                                                                                </p>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='row'>
                                                                                            <div className="col s-12"
                                                                                                style={{ fontSize: "14px" }}>
                                                                                                Phone :
                                                                                            </div>

                                                                                            <div className="col-auto text-capitalize"
                                                                                                style={{ fontSize: "14px" }}
                                                                                            >
                                                                                                {item?.get_agent?.phone}

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='row'>
                                                                                            <div className="col s-12"
                                                                                                style={{ fontSize: "14px" }}>
                                                                                                City :
                                                                                            </div>

                                                                                            <div className="col-auto text-capitalize"
                                                                                                style={{ fontSize: "14px" }}
                                                                                            >
                                                                                                {item?.get_agent?.city}

                                                                                            </div>
                                                                                        </div>



                                                                                        <div className='row'>
                                                                                            <div className="col s-12"
                                                                                                style={{ fontSize: "14px" }}>
                                                                                                State :
                                                                                            </div>

                                                                                            <div className="col-auto text-capitalize"
                                                                                                style={{ fontSize: "14px" }}
                                                                                            >
                                                                                                {item?.get_agent?.state}

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='row'>
                                                                                            <div className="col s-12"
                                                                                                style={{ fontSize: "14px" }}>
                                                                                                Address :
                                                                                            </div>

                                                                                            <div className="col-auto text-capitalize"
                                                                                                style={{ fontSize: "14px" }}
                                                                                            >
                                                                                                {item?.get_agent?.address}

                                                                                            </div>
                                                                                        </div>

                                                                                        <div className='row'>
                                                                                            <div className="col s-12"
                                                                                                style={{ fontSize: "14px" }}>
                                                                                                Selected Date :
                                                                                            </div>

                                                                                            <div className="col-auto text-capitalize"
                                                                                                style={{ fontSize: "14px" }}
                                                                                            >
                                                                                                {item?.get_agent?.selectedDate}

                                                                                                {moment(
                                                                                                    item?.get_agent?.created_at
                                                                                                ).fromNow()}

                                                                                            </div>
                                                                                        </div>

                                                                                        {/* {item.content}
                                                                                        {item.name} */}
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    </Draggable>
                                                                );
                                                            })}
                                                            {provided.placeholder}
                                                        </div>
                                                    );
                                                }}
                                            </Droppable>
                                        </div>
                                    </div>
                                );
                            })}
                        </DragDropContext>
                    </div >
                </div >
            </div >


        </>
    )
}

export default InterviewKanban