import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import logo from "../../logo.svg";
import UseProfile from '../../hooks/profile/UseProfile';
import noImage from "../../assets/no-image-found.webp";

const ViewProfile = () => {


    const user = useSelector((state) => state.adminSlice.userData)



    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        {user?.profile ?
                            <img src={user?.profile} alt="" width={200} height={200} className='rounded-circle' style={{ objectFit: "cover" }} />

                            :
                            <img src={noImage} alt="" width={200} height={200} className='rounded-circle' style={{ objectFit: "cover" }} />
                        }

                    </div>

                    <div className="col-md-6">
                        <h2 className='fw-600'>
                            {user?.name}
                            {/* Agent */}
                        </h2>
                        <p>
                            <small>
                                {user?.description}
                            </small>
                        </p>

                        <hr className='mt-5' />


                        <div className="row mb-2">
                            <div className="col">
                                <h6 className='fw-700'>
                                    Name
                                </h6>
                            </div>
                            <div className="col-auto">
                                {user?.name}
                            </div>

                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <h6 className='fw-700'>
                                    Email
                                </h6>
                            </div>
                            <div className="col-auto">
                                {user?.email}
                            </div>

                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <h6 className='fw-700'>
                                    Phone
                                </h6>
                            </div>
                            <div className="col-auto">
                                {user?.phone}
                            </div>

                        </div>
                        <div className="row">
                            <div className="col">
                                <h6 className='fw-700'>
                                    City
                                </h6>
                            </div>
                            <div className="col-auto">
                                {user?.city}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewProfile