import React, {useState,useEffect} from 'react';
import {saveAgencyRequest, getAgencyRequest} from "../../sevices/agency/agencyService";
import {sweetAlert} from "../../lib/sweetAlert";
import { useParams } from "react-router-dom";


const UseSaveRecord = () => {
    let token = localStorage.getItem('access_token');
    const [isEdit, setIsEdit] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [error, setError] = useState({});
    let { id } = useParams();

    useEffect(() => {
        if (id !== undefined) {
            getRecord(id);
        }
    },[]);

    const handleName = (e) => setName(e.target.value);

    const handleEmail = (e) => setEmail(e.target.value);

    const handlePhone = (e) => setPhone(e.target.value);

    const handleCity = (e) => setCity(e.target.value);

    const saveRecord = async () => {
        let checkError = {};
        if (name === '') {
            checkError.name = 'Name field is required';
        }
        if (email === '') {
            checkError.email = 'Email field is required';
        }
        if (phone === '') {
            checkError.phone = 'Phone field is required';
        }
        if (city === '') {
            checkError.city = 'City field is required';
        }
        setError(checkError);
        if (JSON.stringify(checkError) !== JSON.stringify({})) {
            return false;
        }

        let body =  {
            name:name,
            email:email,
            phone:phone,
            city:city,
        };

        let res = await saveAgencyRequest(body, id, token);
        if (res.status === 'success') {
            await sweetAlert(res.msg, res.status);
            if (id === undefined) {
                setName('');
                setEmail('');
                setPhone('');
                setCity('');
            }
        } else {
            await sweetAlert(res.msg, res.status);
        }
    };

    const getRecord = async (id) => {
        let res = await getAgencyRequest(id, token);
        if (res.status === 'success') {
            setIsEdit(true);
            setName(res.data.name);
            setEmail(res.data.email);
            setPhone(res.data.phone);
            setCity(res.data.city);
        }
    };

    return [
        isEdit,
        name,
        email,
        phone,
        city,
        error,
        handleName,
        handleEmail,
        handlePhone,
        handleCity,
        saveRecord,
    ];
};

export default UseSaveRecord;