import {
  agents,
  agentsGroup,
  agentInterview,
  getAgent,
  saveAgentSkills,
  getAgentSkills,
  getRelatedAgents,
  getWorkingAgency,
  licenseTypeName, licenseTypes,
} from "../../constants/adminWebServices";

const agentRequest = async (appToken, body) => {
  let res = await fetch(agents, {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + appToken,
    }),
    body: JSON.stringify(body),
  });
  return await res.json();
};

const agentGroupBy = async (appToken) => {
  let res = await fetch(agentsGroup, {
    method: "GET",
    headers: new Headers({
      "content-type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + appToken,
    }),
  });
  return await res.json();
};

const licenseTypeNameRequest = async (appToken) => {
  let res = await fetch(licenseTypeName, {
    method: "GET",
    headers: new Headers({
      "content-type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + appToken,
    }),
  });
  return await res.json();
};

const licenseTypeRequest = async (body, token) => {
  let res = await fetch(licenseTypes, {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + token,
    }),
    body: JSON.stringify(body),
  });
  return await res.json();
};

const wishlistAgentRequest = async (id, appToken) => {
  let res = await fetch(`${agentInterview}/${id}`, {
    method: "GET",
    headers: new Headers({
      "content-type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + appToken,
    }),
  });
  return await res.json();
};

const getAgentRequest = async (id, appToken) => {
  let res = await fetch(`${getAgent}/${id}`, {
    method: "GET",
    headers: new Headers({
      "content-type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + appToken,
    }),
  });
  return await res.json();
};

const saveAgentSkillsRequest = async (body, token) => {
  let res = await fetch(saveAgentSkills, {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + token,
    }),
    body: JSON.stringify(body),
  });
  return await res.json();
};

const getAgentSkillsRequest = async (agentId, appToken) => {
  let res = await fetch(`${getAgentSkills}/${agentId}`, {
    method: "GET",
    headers: new Headers({
      "content-type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + appToken,
    }),
  });
  return await res.json();
};

const getRelatedAgentsRequest = async (state, appToken) => {
  let res = await fetch(`${getRelatedAgents}/${state}`, {
    method: "GET",
    headers: new Headers({
      "content-type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + appToken,
    }),
  });
  return await res.json();
};

const getWorkedAgencyRequest = async (agentId, appToken) => {
  let res = await fetch(`${getWorkingAgency}/${agentId}`, {
    method: "GET",
    headers: new Headers({
      "content-type": "application/json",
      accept: "application/json",
      Authorization: "Bearer " + appToken,
    }),
  });
  return await res.json();
};

export {
  agentRequest,
  agentGroupBy,
  licenseTypeNameRequest,
  licenseTypeRequest,
  wishlistAgentRequest,
  getAgentRequest,
  saveAgentSkillsRequest,
  getAgentSkillsRequest,
  getRelatedAgentsRequest,
  getWorkedAgencyRequest,
};
