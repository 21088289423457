import React, {Fragment, useState} from 'react';
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import Nav from "./Nav";
import { useLocation } from 'react-router-dom';

const Master = (props) => {
    const location = useLocation();
    const currentUrl = location.pathname.split('/');
    return (
        <Fragment>
            <div id="loading">
                <div id="loading-center">
                </div>
            </div>
            {(currentUrl[1]==='auth') ?
                props.children
                :
                <>
                    <div className="wrapper">
                        <Sidebar/>
                        <Header/>
                        <div id="content-page" className="content-page">
                            {props.children}
                        </div>
                    </div>
                </>
            }



            <Footer/>

            <Nav/>

        </Fragment>
    );
};

export default Master;