import React, { useEffect } from 'react';
import {useSelector,useDispatch} from "react-redux";
import {useNavigate,useLocation} from "react-router-dom";
import {selectIsSignedIn, selectUserData, setIsSignedIn, setUserData} from "../../features/adminSlice";
import {profileRequest} from "../../sevices/profile/profileService";

const UseAuth = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let location = useLocation();
    const isSignedIn = useSelector(selectIsSignedIn);
    const currentUser = useSelector(selectUserData);
    const currentUrl = location.pathname.split('/');

    useEffect(() => {
        let appToken = localStorage.getItem('access_token');
        if (appToken !== null) {
            checkUser(appToken);
        } else {
            if (currentUrl[1]==='auth') {
                navigate(location.pathname);
            } else {
                navigate('/auth/login');
            }
        }
    },[]);

    const checkUser = async (appToken) => {
        let res = await profileRequest(appToken);
        if (res.status === 'success') {
            dispatch(setIsSignedIn(true));
            dispatch(setUserData(res.data));
        } else {
            navigate('/auth/login');
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('access_token');
        navigate('/auth/login');
    };

    return [
        isSignedIn,
        currentUser,
        handleLogout,
    ];
};

export default UseAuth;