import React,{Fragment} from 'react';
import UseDetail from "../../hooks/agent/UseDetail";
import {Link} from "react-router-dom";

const AgentDetail = () => {

    const [
        agent,
        relatedAgent,
        wishlist,
        agency,
    ] = UseDetail();


    console.log('agent', agent)
    console.log('agency', agency)
    console.log('relatedAgent', relatedAgent)
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="iq-card">
                            <div className="iq-card-body profile-page p-0">
                                <div className="profile-header">
                                    <div className="cover-container">
                                        <img
                                            src="/assets/images/page-img/profile-bg.jpg"
                                            alt="profile-bg"
                                            className="rounded img-fluid"
                                        />
                                    </div>
                                    <div className="profile-info p-4">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                <div className="user-detail pl-5">
                                                    <div className="d-flex flex-wrap align-items-center">
                                                        <div className="profile-img pr-4">
                                                            <img
                                                                src="/assets/images/user/11.png"
                                                                alt="profile-img"
                                                                className="avatar-130 img-fluid"
                                                            />
                                                        </div>
                                                        <div className="profile-detail d-flex align-items-center">
                                                            <h3>{agency[0]?.name}</h3>
                                                            <p className="m-0 pl-3">Agent Detail</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-lg-3 profile-left">
                                <div className="iq-card">
                                    <div className="iq-card-header d-flex justify-content-between">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Skills</h4>
                                        </div>
                                    </div>
                                    <div className="iq-card-body">
                                        <ul className="m-0 p-0">
                                            {agent?.skills?.map((v) => {
                                                return (
                                                    <li className="d-flex mb-2">
                                                        <div className="news-icon">
                                                            <i className="ri-chat-4-fill" />
                                                        </div>
                                                        <p className="news-detail mb-0">
                                                            {v?.skill}
                                                        </p>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="iq-card">
                                    <div className="iq-card-header d-flex justify-content-between">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Worked</h4>
                                        </div>
                                    </div>
                                    <div className="iq-card-body">
                                        <ul className="pages-lists m-0 p-0">
                                            {agency.map((v) => {
                                                return (
                                                    <li className="d-flex align-items-center">
                                                        <div className="user-img img-fluid">
                                                            <img
                                                                src="/assets/images/page-img/45.jpg"
                                                                alt="story-img"
                                                                className="rounded-circle avatar-40"
                                                            />
                                                        </div>
                                                        <div className="media-support-info ml-3">
                                                            <h6>{v?.name}</h6>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 profile-center">
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade active show"
                                        id="profile-activity"
                                        role="tabpanel"
                                    >
                                        <div className="iq-card">
                                            <div className="iq-card-header d-flex justify-content-between">
                                                <div className="iq-header-title">
                                                    <h4 className="card-title">Activity timeline</h4>
                                                </div>
                                            </div>
                                            <div className="iq-card-body">
                                                <ul className="iq-timeline">
                                                    {agent?.history.map((v) => {
                                                        return (
                                                            <li>
                                                                <div className="timeline-dots" style={{borderColor: `${v?.color}`}} />
                                                                <h6 className="float-left mb-1"><span style={{color: `${v?.color}`}}>24 November 2019</span></h6>
                                                                <div className="d-inline-block w-100">
                                                                    <p>
                                                                        {v?.description}
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="iq-card">
                                            <div className="iq-card-header d-flex justify-content-between">
                                                <div className="iq-header-title">
                                                    <h4 className="card-title">About Agent</h4>
                                                </div>
                                            </div>
                                            <div className="iq-card-body">
                                                <div className="mt-2">
                                                    <h6>
                                                        {/*Fuel Type Code:*/}
                                                        License Number:
                                                    </h6>
                                                    <p>{agent?.license_number}</p>
                                                </div>
                                                <div className="mt-2">
                                                    <h6>
                                                        {/*Fuel Type Code:*/}
                                                        License Type:
                                                    </h6>
                                                    <p>{agent?.license_type}</p>
                                                </div>
                                                <div className="mt-2">
                                                    <h6>Street Address:</h6>
                                                    <p>{agent?.address}</p>
                                                </div>
                                                <div className="mt-2">
                                                    <h6>City:</h6>
                                                    <p>{agent?.city}</p>
                                                </div>
                                                <div className="mt-2">
                                                    <h6>State:</h6>
                                                    <p>{agent?.state}</p>
                                                </div>
                                                {/*<div className="mt-2">*/}
                                                {/*    <h6>Zip:</h6>*/}
                                                {/*    <p>{agent?.zip}</p>*/}
                                                {/*</div>*/}
                                                <div className="mt-2">
                                                    <h6>Station Phone:</h6>
                                                    <p>{agent?.phone}</p>
                                                </div>
                                                <div className="mt-2">
                                                    <h6>
                                                        {/*Access Days Time:*/}
                                                        Qualification:
                                                    </h6>
                                                    <p>{agent?.qualification}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 profile-right">
                                <div className="iq-card">
                                    <div className="iq-card-header d-flex justify-content-between">
                                        <div className="iq-header-title">
                                            <h4 className="card-title">Our Suggestions</h4>
                                        </div>
                                        <div className="iq-card-header-toolbar d-flex align-items-center">
                                            <a href="#">
                                                <i className="ri-more-fill" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="iq-card-body">
                                        <ul className="suggestions-lists m-0 p-0">
                                            {relatedAgent.map((v) => {
                                                return (
                                                    <li className="d-flex mb-4 align-items-center">
                                                        <div className="user-img img-fluid">
                                                            <img
                                                                src="/assets/images/user/01.jpg"
                                                                alt="story-img"
                                                                className="rounded-circle avatar-40"
                                                            />
                                                        </div>
                                                        <div className="media-support-info ml-3">
                                                            <h6>{v?.name}</h6>
                                                        </div>
                                                        <div className="add-suggestion">
                                                            <a
                                                                className={`${(v?.agent_selected) ? 'mr-1' : 'text-gray mr-1'}`}
                                                                href="javascript:void(0);"
                                                                onClick={() => wishlist(v?.id)}
                                                            >
                                                                <i className="fa fa-heart"></i>
                                                            </a>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        <Link to="/agent" className="btn btn-primary d-block">
                                            <i className="ri-add-line" /> Load More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AgentDetail;