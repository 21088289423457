// For production
// const baseUrl = "https://ibroker.technottix.com/api/agency_prefix";
// const baseUrl2 = "https://ibroker.technottix.com/api";
// const baseUrl3 = "https://ibroker.technottix.com/api/admin";

//  new base url
const baseUrl = "https://ibroker-be.hiteclogs.com/api/agency_prefix";
const baseUrl2 = "https://ibroker-be.hiteclogs.com/api";
const baseUrl3 = "https://ibroker-be.hiteclogs.com/api/admin";

// For development
// const baseUrl = 'http://insurance-be.test/api/agency_prefix';

const signIn = `${baseUrl}/login`;
const signUp = `${baseUrl}/sign-up`;
const profile = `${baseUrl}/profile`;

const twoFactor = `${baseUrl}/two-factor`;
const sendOtp = `${baseUrl}/send-otp`;
const matchOtp = `${baseUrl}/match-otp`;

// Agents
const agents = `${baseUrl2}/agents`;
const agentsGroup = `${baseUrl}/get-group-data`;
const licenseTypeName = `${baseUrl2}/license-type-name`;
const licenseTypes = `${baseUrl2}/get-lincense-filter`;
const agentInterview = `${baseUrl}/agent-interview`;
const selectedAgent = `${baseUrl}/selected-agent`;
const changeAgentStatus = `${baseUrl}/change-agent-status`;
const allStatuses = `${baseUrl}/get-status`;
const getAgent = `${baseUrl}/get-agent`;
const saveAgentSkills = `${baseUrl}/save-agent-skills`;
const getAgentSkills = `${baseUrl}/get-agent-skills`;
const getRelatedAgents = `${baseUrl}/get-related-agents`;
const getWorkingAgency = `${baseUrl}/get-working-agency`;


const getInterViewKanban = `${baseUrl}/kanban-interviews`;

const userProfileView = `${baseUrl}/user-profile-view`;
const userProfileUpdate = `${baseUrl}/user-profile-update`;

// Agencies
const agencies = `${baseUrl}/agencies`;
const saveAgency = `${baseUrl}/create-update-agency`;
const getAgency = `${baseUrl}/get-agency`;
const deleteAgency = `${baseUrl}/delete-agency`;

const getAllStarted = `${baseUrl}/getting-started`;

export {
  signIn,
  signUp,
  profile,
  agents,
  agentsGroup,
  licenseTypeName,
  licenseTypes,
  agentInterview,
  selectedAgent,
  changeAgentStatus,
  allStatuses,
  getAgent,
  saveAgentSkills,
  getAgentSkills,
  getRelatedAgents,
  getWorkingAgency,
  agencies,
  saveAgency,
  getAgency,
  deleteAgency,
  getAllStarted,
  twoFactor,
  sendOtp,
  matchOtp,
  getInterViewKanban,
  userProfileView,
  userProfileUpdate
};
