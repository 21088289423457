import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginRequest, signUpRequest } from "../../sevices/auth/loginService";
import { useDispatch } from "react-redux";
import { setUserData, setIsSignedIn } from "../../features/adminSlice";
import { sweetAlert } from "../../lib/sweetAlert";
import { useParams } from "react-router-dom";

const UseLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [packageId, setPackageId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({});
  let { id } = useParams();

  useEffect(() => {
    if (id !== undefined) {
      setPackageId(id);
    }
  }, []);

  const handleName = (e) => setName(e.target.value);

  const handleEmail = (e) => setEmail(e.target.value);

  const handlePassword = (e) => setPassword(e.target.value);

  const login = async () => {
    let checkError = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      checkError.email = 'Please enter a valid email address.';
    }
    if (email === "") {
      checkError.email = "Email field is required";
    }
    if (password === "") {
      checkError.password = "Password field is required";
    }
    setError(checkError);
    if (JSON.stringify(checkError) !== JSON.stringify({})) {
      return false;
    }
    let body = {
      email: email,
      password: password,
    };
    let res = await loginRequest(body);
    if (res.status === "success") {
      localStorage.setItem("access_token", res.access_token);
      // dispatch(setIsSignedIn(true));
      dispatch(setUserData(res.data));
      if (res.verify) {
        dispatch(setIsSignedIn(true));
        navigate("/");
      } else {
        navigate("/auth/select-option");
      }
      // navigate('/');
    } else {
      setError({ msg: res.msg });
    }
  };

  const signUp = async () => {
    let checkError = {};
    if (name === "") {
      checkError.name = "Name field is required";
    }
    if (email === "") {
      checkError.email = "Email field is required";
    }
    if (password === "") {
      checkError.password = "Password field is required";
    }
    setError(checkError);
    if (JSON.stringify(checkError) !== JSON.stringify({})) {
      return false;
    }
    let body = {
      package_id: packageId,
      name: name,
      email: email,
      password: password,
    };

    let res = await signUpRequest(body);
    if (res.status === "success") {
      await sweetAlert(res.msg, res.status);
      setName("");
      setEmail("");
      setPassword("");
    } else {
      setError({ msg: res.msg });
    }
  };

  const shiftLogin = () => {
    navigate("/auth/login");
  };

  return [
    name,
    email,
    password,
    error,
    handleName,
    handleEmail,
    handlePassword,
    login,
    signUp,
    shiftLogin,
  ];
};

export default UseLogin;
