import React, { Fragment } from 'react';
import UseAuth from "../../hooks/auth/UseAuth";
import { Link } from "react-router-dom";
import noImage from "../../assets/no-image-found.webp";


const Header = () => {
  const [
    isSignedIn,
    currentUser,
    handleLogout,
  ] = UseAuth()

  return (
    <Fragment>
      <div className="iq-top-navbar">
        <div className="iq-navbar-custom">
          <div className="iq-sidebar-logo">
            <div className="top-logo">
              <a href="index.html" className="logo">
                <div className="iq-light-logo">
                  <img src="/assets/images/logo_light.png" className="img-fluid" alt="" />
                </div>
                <div className="iq-dark-logo">
                  <img src="/assets/images/logo_dark.png" className="img-fluid" alt="" />
                </div>
              </a>
            </div>
          </div>
          <nav className="navbar navbar-expand-lg navbar-light p-0">
            <div className="navbar-left">
              <ul id="topbar-data-icon" className="d-flex p-0 topbar-menu-icon">
                <li className="nav-item">
                  <a href="index.html" className="nav-link font-weight-bold search-box-toggle"><i
                    className="ri-home-4-line" /></a>
                </li>
                <li><a href="chat.html" className="nav-link"><i className="ri-message-line" /></a>
                </li>
                <li><a href="e-commerce-product-list.html" className="nav-link"><i
                  className="ri-file-list-line" /></a></li>
                <li><a href="profile.html" className="nav-link"><i
                  className="ri-question-answer-line" /></a></li>
                <li><a href="todo.html"
                  className="nav-link router-link-exact-active router-link-active"><i
                    className="ri-chat-check-line" /></a></li>
                <li><a href="app/index.html" className="nav-link"><i className="ri-inbox-line" /></a>
                </li>
              </ul>
              <div className="iq-search-bar d-none d-md-block">
                <form action="#" className="searchbox">
                  <input type="text" className="text search-input"
                    placeholder="Type here to search..." />
                  <a className="search-link" href="#"><i className="ri-search-line" /></a>
                  <div className="searchbox-datalink">
                    <h6 className="pl-3 pt-3 pb-3">Pages</h6>
                    <ul className="m-0 pl-3 pr-3 pb-3">
                      <li className="iq-bg-primary-hover rounded"><a href="index.html"
                        className="nav-link router-link-exact-active router-link-active pr-2"><i
                          className="ri-home-4-line pr-2" />Dashboard</a></li>
                      <li className="iq-bg-primary-hover rounded"><a href="dashboard-1.html"
                        className="nav-link router-link-exact-active router-link-active pr-2"><i
                          className="ri-home-3-line pr-2" />Dashboard-1</a></li>
                      <li className="iq-bg-primary-hover rounded"><a href="chat.html"
                        className="nav-link"><i
                          className="ri-message-line pr-2" />Chat</a></li>
                      <li className="iq-bg-primary-hover rounded"><a href="calendar.html"
                        className="nav-link"><i
                          className="ri-calendar-2-line pr-2" />Calendar</a></li>
                      <li className="iq-bg-primary-hover rounded"><a href="profile.html"
                        className="nav-link"><i
                          className="ri-profile-line pr-2" />Profile</a></li>
                      <li className="iq-bg-primary-hover rounded"><a href="todo.html"
                        className="nav-link"><i
                          className="ri-chat-check-line pr-2" />Todo</a></li>
                      <li className="iq-bg-primary-hover rounded"><a href="app/index.html"
                        className="nav-link"><i
                          className="ri-mail-line pr-2" />Email</a></li>
                      <li className="iq-bg-primary-hover rounded"><a
                        href="e-commerce-product-list.html" className="nav-link"><i
                          className="ri-message-line pr-2" />Product Listing</a></li>
                      <li className="iq-bg-primary-hover rounded"><a
                        href="e-commerce-product-detail.html" className="nav-link"><i
                          className="ri-file-list-line pr-2" />Product Details</a></li>
                      <li className="iq-bg-primary-hover rounded"><a href="pages-faq.html"
                        className="nav-link"><i
                          className="ri-compasses-line pr-2" />Faq</a></li>
                      <li className="iq-bg-primary-hover rounded"><a href="form-wizard.html"
                        className="nav-link"><i
                          className="ri-clockwise-line pr-2" />Form-wizard</a></li>
                    </ul>
                  </div>
                </form>
              </div>
            </div>
            <button className="navbar-toggler d-none" type="button" data-toggle="collapse"
              data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
              aria-label="Toggle navigation">
              <i className="ri-menu-3-line" />
            </button>
            <div className="iq-menu-bt align-self-center">
              <div className="wrapper-menu">
                <i className="ri-menu-3-line" />
                {/* <div className="main-circle"><i className="ri-arrow-left-s-line" /></div>
                <div className="hover-circle"><i className="ri-arrow-right-s-line" /></div> */}
              </div>
            </div>
            <div className="collapse navbar-collapse " id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto navbar-list d-none">
                <li className="nav-item">
                  <a href="#" className="search-toggle iq-waves-effect">
                    <div id="lottie-beil" />
                    <span className="bg-danger dots" />
                  </a>
                  <div className="iq-sub-dropdown">
                    <div className="iq-card shadow-none m-0">
                      <div className="iq-card-body p-0 ">
                        <div className="bg-primary p-3">
                          <h5 className="mb-0 text-white">All Notifications
                            <small className="badge  badge-light float-right pt-1">4
                            </small>
                          </h5>
                        </div>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className>
                              <img className="avatar-40 rounded"
                                src="/assets/images/user/01.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Emma Watson Nik</h6>
                              <small className="float-right font-size-12">Just Now
                              </small>
                              <p className="mb-0">95 MB</p>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className>
                              <img className="avatar-40 rounded"
                                src="/assets/images/user/02.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">New customer is join</h6>
                              <small className="float-right font-size-12">5 days ago
                              </small>
                              <p className="mb-0">Jond Nik</p>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className>
                              <img className="avatar-40 rounded"
                                src="/assets/images/user/03.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Two customer is left</h6>
                              <small className="float-right font-size-12">2 days ago
                              </small>
                              <p className="mb-0">Jond Nik</p>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className>
                              <img className="avatar-40 rounded"
                                src="/assets/images/user/04.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">New Mail from Fenny</h6>
                              <small className="float-right font-size-12">3 days ago
                              </small>
                              <p className="mb-0">Jond Nik</p>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a href="#" className="search-toggle iq-waves-effect">
                    <div id="lottie-mail" />
                    <span className="bg-primary count-mail" />
                  </a>
                  <div className="iq-sub-dropdown">
                    <div className="iq-card shadow-none m-0">
                      <div className="iq-card-body p-0 ">
                        <div className="bg-primary p-3">
                          <h5 className="mb-0 text-white">All Messages
                            <small className="badge  badge-light float-right pt-1">5
                            </small>
                          </h5>
                        </div>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className>
                              <img className="avatar-40 rounded"
                                src="/assets/images/user/01.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Nik Emma Watson</h6>
                              <small className="float-left font-size-12">13 Jun
                              </small>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className>
                              <img className="avatar-40 rounded"
                                src="/assets/images/user/02.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Lorem Ipsum Watson</h6>
                              <small className="float-left font-size-12">20 Apr
                              </small>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className>
                              <img className="avatar-40 rounded"
                                src="/assets/images/user/03.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Why do we use it?</h6>
                              <small className="float-left font-size-12">30 Jun
                              </small>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className>
                              <img className="avatar-40 rounded"
                                src="/assets/images/user/04.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Variations Passages</h6>
                              <small className="float-left font-size-12">12 Sep
                              </small>
                            </div>
                          </div>
                        </a>
                        <a href="#" className="iq-sub-card">
                          <div className="media align-items-center">
                            <div className>
                              <img className="avatar-40 rounded"
                                src="/assets/images/user/05.jpg" alt="" />
                            </div>
                            <div className="media-body ml-3">
                              <h6 className="mb-0 ">Lorem Ipsum generators</h6>
                              <small className="float-left font-size-12">5 Dec</small>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <ul className="navbar-list">
              <li>
                <a href="#"
                  className="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded">
                  {
                    currentUser?.profile ?
                      <img src={currentUser?.profile} width={64 } height={64}  className="img-fluid rounded mr-3" alt="user" />
                      :
                      <img src={noImage} className="img-fluid rounded mr-3" alt="user" />
                  }
                  <div className="caption">
                    <h6 className="mb-0 line-height text-white">{currentUser?.name}</h6>
                    <span className="font-size-12 text-white">Agency</span>
                  </div>
                </a>
                <div className="iq-sub-dropdown iq-user-dropdown">
                  <div className="iq-card shadow-none m-0">
                    <div className="iq-card-body p-0 ">
                      <div className="bg-primary p-3">
                        <h5 className="mb-0 text-white line-height">{currentUser?.name}</h5>
                        <span className="text-white font-size-12">Agency</span>
                      </div>
                      <Link to="/profile" className="iq-sub-card iq-bg-primary-hover">
                        <div className="media align-items-center">
                          <div className="rounded iq-card-icon iq-bg-primary">
                            <i className="ri-file-user-line" />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">My Profile</h6>
                            <p className="mb-0 font-size-12">View personal profile
                              details.</p>
                          </div>
                        </div>
                      </Link>
                      <Link to="/profile-edit" className="iq-sub-card iq-bg-primary-hover">
                        <div className="media align-items-center">
                          <div className="rounded iq-card-icon iq-bg-primary">
                            <i className="ri-profile-line" />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Edit Profile</h6>
                            <p className="mb-0 font-size-12">Modify your personal
                              details.</p>
                          </div>
                        </div>
                      </Link>
                      <a href="javascript:void(0)"
                        className="iq-sub-card iq-bg-primary-hover d-none">
                        <div className="media align-items-center">
                          <div className="rounded iq-card-icon iq-bg-primary">
                            <i className="ri-lock-line" />
                          </div>
                          <div className="media-body ml-3">
                            <h6 className="mb-0 ">Privacy Settings</h6>
                            <p className="mb-0 font-size-12">Control your privacy
                              parameters.</p>
                          </div>
                        </div>
                      </a>
                      <div className="d-inline-block w-100 text-center p-3">
                        <a
                          onClick={handleLogout}
                          href="javascript:void(0)"
                          className="btn btn-primary dark-btn-primary"
                          role="button">Sign out<i className="ri-login-box-line ml-2" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
