import React, {useEffect, useState} from 'react';
import {getAllStartedRequest} from "../../sevices/started/startedService";

const UseStarted = () => {
    let token = localStorage.getItem('access_token');
    const [gettingStarted, setGettingStarted] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        let res = await getAllStartedRequest(token);
        if (res.status === 'success') {
            setGettingStarted(res.data);
        }
    };

    return [
        gettingStarted,
    ];
};

export default UseStarted;