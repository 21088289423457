import React, { useEffect, useState } from "react";
import { agents, licenseTypes } from "../../constants/adminWebServices";
import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-bs4/js/dataTables.bootstrap4";
import "datatables.net-buttons-bs4";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import {
  agentGroupBy,
  agentRequest,
  licenseTypeNameRequest,
  licenseTypeRequest,
  wishlistAgentRequest,
} from "../../sevices/agent/agentService";
import { createRoot } from "react-dom/client";
import { sweetAlert, sweetAlertConfirmation } from "../../lib/sweetAlert";
import { useNavigate } from "react-router-dom";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const UseAgent = () => {
  const navigate = useNavigate();
  let newDataTable = "";
  const [agent, setAgent] = useState([]);
  let token = localStorage.getItem("access_token");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [licenseType, setLicenseType] = useState([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [issue, setIssue] = useState("");
  const [expiry, setExpiry] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    getTableData();
    getData();
    getLicense();
  }, []);

  const getData = async () => {
    let res = await agentGroupBy(token);
    if (res?.status === "success") {
      setCities(res?.data?.cities);
      setStates(res?.data?.states);
    }
  };
  const getLicense = async () => {
    let res = await licenseTypeNameRequest(token);
    console.log("license", res?.data);
    if (res?.response == "success") {
      setLicenseType(res?.data);
      console.log("license", res?.data);
    }
  };

  const multiselectRef = React.useRef();

  const resetValues = () => {
    // By calling the belowe method will reset the selected values programatically
    multiselectRef.current.resetSelectedValues();
  };

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValues([...selectedValues, selectedItem?.license_type]);
  };

  const onRemove = (selectedList, removedItem) => {
    const removeItem = selectedValues?.filter(
      (val) => val !== removedItem?.license_type
    );
    setSelectedValues(removeItem);
  };

  const filterAgentsss = async () => {
    try {
      const body = {
        license_type: selectedValues?.toString(","),
        city: city,
        state: state,
        license_issue_date: issue,
        expiration_date: expiry,
      };
      const agent = await licenseTypeRequest(body, token);
      //   if (agent?.response == "success") {
      console.log("data", agent);
      setAgent(agent?.data);
      setTotalRows(agent.recordsTotal);
    } catch (e) {
      console.log("e", e.message);
    }
  };

  const filterAgents = async () => {
    // if(selectedValues?.length > 0){
    // document.getElementById("lisence-type").innerHTML = "";
    getTableData();
    // try {
    //   const body = {
    //     license_type: selectedValues?.toString(","),
    //     city: city,
    //     state: state,
    //     license_issue_date: issue,
    //     expiration_date: expiry,
    //   };
    //   const agent = await agentRequest(token, body);
    //   console.log("data", agent);
    //   setAgent(agent?.data);
    //   setTotalRows(agent.recordsTotal);
    // } catch (e) {
    //   console.log("e", e.message);
    // }

    /*$("#crmTable").DataTable({
            serverSide: true,
            processing: true,
            bInfo: true,
            bPaginate: true,
            bLengthChange: false,
            pageLength: 10,
            ajax: {
                url: licenseTypes,
                data: function (data) {
                    data.license_type = selectedValues?.toString(",");
                    data.city = city;
                    data.state = state;
                    data.issue = issue;
                    data.expiry = expiry;
                },
                type: "POST",
                beforeSend: function (request) {
                    request.setRequestHeader("Authorization", "Bearer " + token);
                },
            },
            columns: [
                {
                    data: "id",
                    targets: 0,
                    createdCell: (data, type, row, meta) => {
                        createRoot(data).render(
                            <>
                                {row.agent_selected ? (
                                    <a
                                        onClick={() => wishlist(row.id)}
                                        href="javascript:void(0)"
                                        className="mr-1"
                                    >
                                        <i className="fa fa-heart"></i>
                                    </a>
                                ) : (
                                    <a
                                        onClick={() => wishlist(row.id)}
                                        href="javascript:void(0)"
                                        className="text-gray mr-1"
                                    >
                                        <i className="fa fa-heart"></i>
                                    </a>
                                )}
                                <a
                                    onClick={() => gotoHere(`/agent-detail/${row.id}`)}
                                    href="javascript:void(0)"
                                    className="text-dark mr-1"
                                >
                                    <i className="fa fa-eye" style={{color: "grey"}}></i>
                                </a>
                                <a
                                    onClick={() => gotoHere(`/add-edit-agent/${row.id}`)}
                                    href="javascript:void(0)"
                                    className="text-dark mr-1"
                                >
                                    <i className="fa fa-edit" style={{color: "grey"}}></i>
                                </a>
                            </>
                        );
                    },
                },
                {data: "name"},
                {data: "phone"},
                {data: "city"},
                {data: "state"},
                {data: "license_type"},
                {data: "expiration_date"},
            ],
            bDestroy: true,
            dom: "Blfrtip",
            buttons: ["pdf", "csv"],
        });*/
    // }else{
    //   document.getElementById("lisence-type").innerHTML = "Please Select First";
    // }
  };

  const getTableData = async (page) => {
    setLoading(true);
    try {
      const body = {
        license_type: selectedValues?.toString(","),
        city: city,
        state: state,
        license_issue_date: issue,
        expiration_date: expiry,
        start: page === undefined ? 0 : (page - 1) * perPage,
        length: 10,
      };
      const agent = await agentRequest(token, body);
      console.log("data", agent);
      setAgent(agent?.data);
      setTotalRows(agent.recordsTotal);
      setLoading(false);
    } catch (e) {
      console.log("e", e.message);
      setLoading(false);
    }

    /*$("#crmTable").DataTable({
          serverSide: true,
          processing: true,
          bInfo: true,
          bPaginate: true,
          bLengthChange: false,
          pageLength: 10,
          ajax: {
            url: agents,
            data: function (data) {
              data.city = city;
              data.state = state;
              data.issue = issue;
              data.expiry = expiry;
            },
            type: "POST",
            beforeSend: function (request) {
              request.setRequestHeader("Authorization", "Bearer " + token);
            },
          },
          columns: [
            {
              data: "id",
              targets: 0,
              createdCell: (data, type, row, meta) => {
                createRoot(data).render(
                  <>
                    {row.agent_selected ? (
                      <a
                        onClick={() => wishlist(row.id)}
                        href="javascript:void(0)"
                        className="mr-1"
                      >
                        <i className="fa fa-heart"></i>
                      </a>
                    ) : (
                      <a
                        onClick={() => wishlist(row.id)}
                        href="javascript:void(0)"
                        className="text-gray mr-1"
                      >
                        <i className="fa fa-heart"></i>
                      </a>
                    )}
                    <a
                      onClick={() => gotoHere(`/agent-detail/${row.id}`)}
                      href="javascript:void(0)"
                      className="text-dark mr-1"
                    >
                      <i className="fa fa-eye" style={{ color: "grey" }}></i>
                    </a>
                    <a
                      onClick={() => gotoHere(`/add-edit-agent/${row.id}`)}
                      href="javascript:void(0)"
                      className="text-dark mr-1"
                    >
                      <i className="fa fa-edit" style={{ color: "grey" }}></i>
                    </a>
                  </>
                );
              },
            },
            { data: "name" },
            { data: "phone" },
            { data: "city" },
            { data: "state" },
            { data: "license_type" },
            { data: "expiration_date" },
          ],
          bDestroy: true,
          dom: "Blfrtip",
          buttons: ["pdf", "csv"],
        });*/
  };

  const handleReset = async () => {
    let select = selectedValues;
    setCity("");
    setExpiry("");
    setIssue("");
    setState("");
    resetValues();
    select && select?.length > 0 && setSelectedValues([]);
  };

  const handleRefetch = () => {
    getTableData();
  };

  const wishlist = async (id) => {
    let result = await sweetAlertConfirmation();
    if (result.isConfirmed) {
      let res = await wishlistAgentRequest(id, token);
      if (res.status === "success") {
        await sweetAlert(res.msg, res.status);
        getTableData();
      } else {
        await sweetAlert(res.msg, res.status);
      }
    }
  };

  const handleCity = (e) => setCity(e.target.value);

  const handleState = (e) => setState(e.target.value);

  const handleIssue = (e) => setIssue(e.target.value);

  const handleExpiry = (e) => setExpiry(e.target.value);

  const searchRecord = () => {
    getTableData();
  };

  const gotoHere = (url) => {
    navigate(url);
  };

  const handlePageChange = (page) => {
    getTableData(page);
  };

  const columns = [
    {
      name: "Action",
      cell: (row, i) => {
        return (
          <>
            {row.agent_selected ? (
              <a
                onClick={() => wishlist(row.id)}
                href="javascript:void(0)"
                className="mr-1"
              >
                <i className="fa fa-heart"></i>
              </a>
            ) : (
              <a
                onClick={() => wishlist(row.id)}
                href="javascript:void(0)"
                className="text-gray mr-1"
              >
                <i className="fa fa-heart"></i>
              </a>
            )}
            <a
              onClick={() => gotoHere(`/agent-detail/${row.id}`)}
              href="javascript:void(0)"
              className="text-dark mr-1"
            >
              <i className="fa fa-eye" style={{ color: "grey" }}></i>
            </a>
            <a
              onClick={() => gotoHere(`/add-edit-agent/${row.id}`)}
              href="javascript:void(0)"
              className="text-dark mr-1"
            >
              <i className="fa fa-edit" style={{ color: "grey" }}></i>
            </a>
          </>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "City",
      selector: (row) => row.city,
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row.state,
      sortable: true,
    },
    {
      name: "License Type",
      selector: (row) => row.license_type,
      sortable: true,
    },
    {
      name: "License Expiry Date",
      selector: (row) => row.expiration_date,
      sortable: true,
    },
  ];

  return [
    cities,
    states,
    licenseType,
    city,
    state,
    issue,
    expiry,
    handleCity,
    handleState,
    handleIssue,
    handleExpiry,
    searchRecord,
    onSelect,
    onRemove,
    filterAgents,
    columns,
    agent,
    loading,
    totalRows,
    handlePageChange,
    multiselectRef,
    handleReset,
    handleRefetch,
  ];
};

export default UseAgent;
