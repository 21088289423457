import React, { Fragment } from 'react';
import UseInterview from "../../hooks/interview/UseInterview";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { customStyles } from "../../customTable";
import DataTable from "react-data-table-component";
import { BsFillGrid1X2Fill, IconName } from "react-icons/bs";
import { FaThList } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { downloadCSV } from '../../utils';


const Interview = () => {

    const [
        statuses,
        status,
        reqStatus,
        show,
        handleStatus,
        reqHandleStatus,
        handleClose,
        changeStatus,
        columns,
        filteredItems,
        interviews,
        loading,
        totalRows,
        handlePageChange,
        handleFilter,
        filtered
    ] = UseInterview();


    return (
        <Fragment>
            {/* 
            <div className="row align-items-center">
                <div className="col-auto pe-0">

                    <h1>Interview</h1>
                </div>
                <div className="col">
                    <div className="row">

                        <div className="col-auto ">
                            <Link to={""} className='btn btn-light border p-2' style={{ lineHeight: "95%" }}>
                                <FaThList size={16} />
                            </Link>
                        </div>
                        <div className="col-auto pl-0">
                            <Link to={"/lead-kanban"} className='btn btn-light border p-2' style={{ lineHeight: "95%" }}>
                                <BsFillGrid1X2Fill size={16} />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="col-4">
                    <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <select id="status" className="form-control" onChange={handleStatus} value={status}>
                            <option value=""> -- Select -- </option>
                            {statuses.map((i) => {
                                return (
                                    <option value={i?.id}>{i?.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
            </div> */}

            <div className="container-fluid">
                <div className="row align-items-center">
                    {/* <div className="col-md-12">
                        <h1>Interview</h1>
                        <div className="row">
                            <div className="col-4">
                                <div className="form-group">
                                    <label htmlFor="status">Status</label>
                                    <select id="status" className="form-control" onChange={handleStatus} value={status}>
                                        <option value=""> -- Select -- </option>
                                        {statuses.map((i) => {
                                            return (
                                                <option value={i?.id}>{i?.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-auto pe-0">

                        <h1>Interview</h1>
                    </div>
                    <div className="col">
                        <div className="row">

                            <div className="col-auto ">
                                <Link to={""} className='btn btn-primary border p-2' style={{ lineHeight: "95%" }}>
                                    <FaThList size={16} />
                                </Link>
                            </div>
                            <div className="col-auto pl-0">
                                <Link to={"/interview-kanban"} className='btn btn-light border p-2' style={{ lineHeight: "95%" }}>
                                    <BsFillGrid1X2Fill size={16} />
                                </Link>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <select id="status" className="form-control" onChange={handleStatus} value={status}>
                                <option value=""> -- Select -- </option>
                                {statuses.map((i) => {
                                    return (
                                        <option value={i?.id}>{i?.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-lg-12 d-none">
                        <table id="crmTable" className="table table-striped table-bordered" style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Street Address</th>
                                    <th>Status</th>
                                    <th>Selected Date</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    </div>
                    <div className="col-lg-12">
                        <div className="row justify-content-between mb-2">

                            <div className='col-auto mb-2'>
                                <button
                                    onClick={() => downloadCSV(filtered, 'interviews')}
                                    className='btn btn-success'>
                                    Export CSV
                                </button>

                            </div>
                            <div className="col-md-3">

                                <input type="text" placeholder='Search' className='form-control' onChange={handleFilter} />
                            </div>
                        </div>
                        <DataTable
                            fixedHeaderScrollHeight="500px"
                            columns={columns}
                            data={filteredItems}
                            customStyles={customStyles}
                            pagination={true}
                            fixedHeader={true}
                            progressPending={loading}
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                        // dense={true}
                        />
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <select id="reqStatus" className="form-control"
                            onChange={reqHandleStatus} value={reqStatus}
                        >
                            {statuses.map((i) => {
                                return (
                                    <option value={i?.id}>{i?.name}</option>
                                )
                            })}
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={changeStatus}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default Interview;