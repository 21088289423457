import React, {Fragment} from 'react';
import UseStarted from "../../hooks/started/UseStarted";

const GettingStarted = () => {

    const [
        gettingStarted,
    ] = UseStarted();

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                        <div className="col-md-12">
                            <h1>Getting Started</h1>
                        </div>
                        {gettingStarted.map((i) => {
                            return (
                                <div className="col-sm-12 col-lg-6">
                                    <div className="iq-card">
                                        <div className="iq-card-header d-flex justify-content-between">
                                            <div className="iq-header-title">
                                                <h4 className="card-title">{i?.title}</h4>
                                            </div>
                                        </div>
                                        <div className="iq-card-body">
                                            <p>{i?.description}</p>
                                            <div className="embed-responsive embed-responsive-16by9">
                                                <iframe
                                                    src={i?.video}
                                                    frameBorder="0"
                                                    allow="autoplay; encrypted-media"
                                                    allowFullScreen
                                                    title="video"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </Fragment>
    );
};

export default GettingStarted;