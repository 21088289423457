import React,{Fragment} from 'react';
import UseSaveRecord from "../../hooks/agency/UseSaveRecord";

const AddEdit = () => {

    const [
        isEdit,
        name,
        email,
        phone,
        city,
        error,
        handleName,
        handleEmail,
        handlePhone,
        handleCity,
        saveRecord,
    ] = UseSaveRecord();

    return (
        <Fragment>
            <div className="container-fluid">
                <h1>{(isEdit === true) ? 'Edit' : 'Add'} User</h1>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" id="name" className="form-control"
                                   onChange={handleName}
                                   value={name} />
                            {(error.name) && <span className="text-danger">{error.name}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="text" id="email" className="form-control"
                                   onChange={handleEmail}
                                   value={email} />
                            {(error.email) && <span className="text-danger">{error.email}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input type="text" id="phone" className="form-control"
                                   onChange={handlePhone}
                                   value={phone} />
                            {(error.phone) && <span className="text-danger">{error.phone}</span>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="city">City</label>
                            <input type="text" id="city" className="form-control"
                                   onChange={handleCity}
                                   value={city}/>
                            {(error.city) && <span className="text-danger">{error.city}</span>}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button onClick={saveRecord} className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AddEdit;