import {
    signIn,
    signUp,
    sendOtp,
    matchOtp,
    twoFactor

} from '../../constants/adminWebServices';

const loginRequest = async (body) => {
    let res = await fetch(signIn, {
        method: "POST",
        // mode:'no-cors',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body),
    });
    return await res.json();
};

const signUpRequest = async (body) => {
    let res = await fetch(signUp, {
        method: "POST",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json'
        }),
        body: JSON.stringify(body),
    });
    return await res.json();
};

const sendOtpRequest = async (token, types) => {
    let url = `${sendOtp}`
    types && (url = `${sendOtp}?type=${types}`)
    let res = await fetch(url, {
        method: "GET",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
    });
    return await res.json();
};

const matchOtpRequest = async (token, otp) => {
    let url = `${matchOtp}`
    otp && (url = `${matchOtp}?otp=${otp}`)
    let res = await fetch(url, {
        method: "GET",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
    });
    return await res.json();
};

const twoFactorRequest = async (token, body) => {
    let res = await fetch(twoFactor, {
        method: "POST",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(body),
    });
    return await res.json();
};

export {
    loginRequest,
    signUpRequest,
    twoFactorRequest,
    sendOtpRequest,
    matchOtpRequest
}