import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Form";
import { twoFactorRequest } from "../../sevices/auth/loginService";
import { sweetAlert } from "../../lib/sweetAlert";
import { useSelector, useDispatch } from "react-redux";
import { profileRequest } from "../../sevices/profile/profileService";
import { setIsSignedIn, setUserData } from "../../features/adminSlice";

const TwoFactorCode = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.adminSlice?.userData);
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleTwoFactor = async (event) => {
    event.preventDefault();
    if (email && phone) {
      let token = localStorage.getItem("access_token");
      try {
        const create = await twoFactorRequest(token, {
          email,
          phone,
          is_two_factor: showForm ? 1 : 0,
        });
        if (create?.status === "success") {
          await sweetAlert("Updated Successfully", create.status);
          updateUserData()
        } else {
          await sweetAlert("Something Went Wrong", create.status);
        }
      } catch (e) {
        console.log(e.messaage);
      }
    }
  };
  const handleEmptyTwoFactor = async () => {
    // event.preventDefault();
    // return
    // if (email && phone) {
    if (showForm) {

      let token = localStorage.getItem("access_token");
      try {
        const create = await twoFactorRequest(token, {
          email: "",
          phone: "",
          is_two_factor: 0,
        });
        if (create?.status === "success") {
          await sweetAlert("Updated Successfully", create.status);
          updateUserData()

        } else {
          await sweetAlert("Something Went Wrong", create.status);
        }
        setShowForm(!showForm)
      } catch (e) {
        console.log(e.messaage);
      }
    } else {
      setShowForm(true)

    }

    // }

  };

  const updateUserData = async () => {
    let appToken = localStorage.getItem('access_token');
    let res = await profileRequest(appToken);
    if (res.status === 'success') {
      dispatch(setUserData(res.data));
    }
  }

  const setEmailWithAsterisk = (email) => {
    // const atIndex = email?.indexOf("@");
    // if (atIndex >= 0) {
    //   const prefix = email?.substring(0, atIndex);
    //   const maskedPrefix =
    //     prefix?.substring(0, Math.min(prefix.length, 3)) +
    //     "*".repeat(prefix.length - 3);
    //   const domain = email?.substring(atIndex);
    //   return maskedPrefix + domain;
    // } else {
    return email; // Handle email addresses without an '@' gracefully
    // }
  };
  console.log(showForm);
  useEffect(() => {
    if (user) {
      setEmail(setEmailWithAsterisk(user?.email_two_factor));
      setPhone(user?.phone_two_factor);
      setShowForm(Boolean(user?.is_enable_two_factor));
    }
  }, [user]);
  return (
    <>
      <div className="container">
        <>
          <div className="row align-items-center">
            <div className="col-auto">
              <h1 className="mb-0">Two Factor Code</h1>
            </div>
            <div className="col">
              <Form>
                <Form.Check
                  // onChange={() => setShowForm(!showForm)}
                  onChange={handleEmptyTwoFactor}
                  // onClick={handleEmptyTwoFactor}
                  value={showForm}
                  checked={showForm}
                  type="switch"
                  id="custom-switch"
                  label="Check this switch"
                />
              </Form>
            </div>
          </div>

          {showForm && (
            <div className="row mt-5">
              <div className="col-md-12">
                <div className="card p-4 border-0 shadow">
                  <Form onSubmit={handleTwoFactor}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="fw-600 s-14">
                        Email address
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onFocus={() => setEmail(email)}
                        onBlur={() => setEmail(setEmailWithAsterisk(email))}
                        required={true}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label className="fw-600 s-14">
                        Phone Number
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone No"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required={true}
                      />
                    </Form.Group>

                    <button type="submit" className="btn btn-primary ">
                      Submit
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default TwoFactorCode;
