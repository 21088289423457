import React, { Fragment } from 'react';
import UseAgency from "../../hooks/agency/UseAgency";
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component'
import { customStyles } from "../../customTable";

const Agency = () => {

    const [
        // deleteRecord,
        columns,
        filteredItems,
        totalRows,
        loading,
        handlePageChange,
        filterInput,
        handleFilter,
    ] = UseAgency();

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Users</h1>
                    </div>
                    {/* <div className="col-md-auto">
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">@</span>
                            <input
                                type="text"
                                className="form-control"
                                value={filterInput}
                                onChange={handleFilter}
                                placeholder="Username"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                            />
                        </div>
                    </div> */}
                    <div className="col-md-auto text-right">
                        <Link to={'/add-edit-user'}>
                            <span className="btn btn-primary btn-circle btn-sm" title="Add">
                                <i className="fa fa-plus"></i>
                            </span>
                        </Link>
                    </div>
                    <div className="col-lg-12 d-none">
                        <table id="crmTable" className="table table-striped table-bordered" style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    </div>
                    <div className="col-lg-12">
                        <div className="row justify-content-end mb-2">
                            <div className="col-md-3">
                                <input type="text" placeholder='Search' className='form-control' onChange={handleFilter} />
                            </div>
                        </div>
                        <DataTable
                            fixedHeaderScrollHeight="500px"
                            columns={columns}
                            data={filteredItems}
                            customStyles={customStyles}
                            pagination={true}
                            fixedHeader={true}
                            progressPending={loading}
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangePage={handlePageChange}
                        // dense={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Agency;