import {
    getAllStarted,
} from "../../constants/adminWebServices";

const getAllStartedRequest = async (token) => {
    let res = await fetch(`${getAllStarted}`, {
        method: "GET",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    });
    return await res.json();
};

export {
    getAllStartedRequest,
}