import {
    saveAgency,
    getAgency,
    deleteAgency, agencies,
} from "../../constants/adminWebServices";


const saveAgencyRequest = async (body, id, token) => {
    let saveAgencyUrl = saveAgency;
    if (id !== undefined) {
        saveAgencyUrl = `${saveAgency}/${id}`;
    }
    let res = await fetch(saveAgencyUrl, {
        method: "POST",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(body),
    });
    return await res.json();
};

const getAgencyRequest = async (id, token) => {
    let res = await fetch(`${getAgency}/${id}`, {
        method: "GET",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    });
    return await res.json();
};

const getAgenciesRequest = async (token, body) => {
    let res = await fetch(`${agencies}`, {
        method: "Post",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        }),
        body: JSON.stringify(body),
    });
    return await res.json();
};

const deleteAgencyRequest = async (id, token) => {
    let res = await fetch(`${deleteAgency}/${id}`, {
        method: "GET",
        headers: new Headers({
            'content-type': 'application/json',
            'accept': 'application/json',
            'Authorization': 'Bearer ' + token
        })
    });
    return await res.json();
};

export {
    saveAgencyRequest,
    getAgencyRequest,
    getAgenciesRequest,
    deleteAgencyRequest,
}