import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
    name: "user",
    initialState: {
        isSignedIn: false,
        userData: null,
    },
    reducers: {
        setIsSignedIn: (state, action) => {
            state.isSignedIn = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        },

        setSaveUser: (state, action) => {
            const { name, description, profile } = action.payload;
            const body = {
                name: name,
                description: description,
                profile, profile

            }
            state.userData = { ...state?.userData, ...body };
        },

    },
});

export const {
    setIsSignedIn,
    setUserData,
    setSaveUser,
} = adminSlice.actions;

export const selectIsSignedIn = (state) => state.adminSlice.isSignedIn;
export const selectUserData = (state) => state.adminSlice.userData;

export default adminSlice.reducer;
