import React, { Fragment, useState } from "react";
import UseAgent from "../../hooks/agent/UseAgent";
import Multiselect from "multiselect-react-dropdown";
import DataTable from "react-data-table-component";
import { customStyles } from "../../customTable";

const Agent = () => {
  const [
    cities,
    states,
    licenseType,
    city,
    state,
    issue,
    expiry,
    handleCity,
    handleState,
    handleIssue,
    handleExpiry,
    searchRecord,
    onSelect,
    onRemove,
    filterAgents,
    columns,
    agent,
    loading,
    totalRows,
    handlePageChange,
    multiselectRef,
    handleReset,
    handleRefetch,
  ] = UseAgent();

  const objectArray = [
    { key: "Option 1", cat: "Group 1" },
    { key: "Option 2", cat: "Group 1" },
    { key: "Option 3", cat: "Group 1" },
    { key: "Option 4", cat: "Group 2" },
    { key: "Option 5", cat: "Group 2" },
    { key: "Option 6", cat: "Group 2" },
    { key: "Option 7", cat: "Group 2" },
  ];

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <h1>Agent</h1>
            <div className="row">
              <div className="col-5">
                <div className="form-group">
                  <label htmlFor="city">License Type</label>
                  <Multiselect
                    options={licenseType}
                    displayValue="license_type"
                    onSelect={onSelect}
                    onRemove={onRemove}
                    // selectedValues={selectedValues}
                    // selectionLimit="2"
                    // selectedValues={licenseType}
                    ref={multiselectRef}
                    selectedValues={false}
                    showCheckbox={true}
                  />
                  <span id="lisence-type" className="text-danger"></span>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <select
                    id="city"
                    className="form-control"
                    onChange={handleCity}
                    value={city}
                  >
                    <option value=""> -- Select -- </option>
                    {cities.map((i, index) => {
                      return (
                        <option key={index} value={i}>
                          {i}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="state">State</label>
                  <select
                    id="state"
                    className="form-control"
                    onChange={handleState}
                    value={state}
                  >
                    <option value=""> -- Select -- </option>
                    {states.map((i) => {
                      return <option value={i}>{i}</option>;
                    })}
                  </select>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="issue">License issue date</label>
                  <input
                    type="date"
                    id="issue"
                    className="form-control"
                    onChange={handleIssue}
                    value={issue}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label htmlFor="expiry">License expiry date</label>
                  <input
                    type="date"
                    id="expiry"
                    className="form-control"
                    onChange={handleExpiry}
                    value={expiry}
                  />
                </div>
              </div>
              <div className="col-md-12 text-right d-flex">
                <button
                  onClick={handleRefetch}
                  className="btn btn-primary mt-5 mr-auto"
                >
                  Refetch
                </button>
                <button
                  onClick={handleReset}
                  className="btn btn-primary mt-5 mr-2"
                >
                  Reset
                </button>
                <button onClick={filterAgents} className="btn btn-primary mt-5">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-12 d-none">
            <table
              id="crmTable"
              className="table table-striped table-bordered"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>City</th>
                  <th>State</th>
                  <th>License Type</th>
                  <th>License Expiry Date</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </div>
          <div className="col-lg-12">
            <DataTable
              fixedHeaderScrollHeight="500px"
              columns={columns}
              data={agent}
              customStyles={customStyles}
              pagination={true}
              fixedHeader={true}
              progressPending={loading}
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              ref={multiselectRef}

              // dense={true}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Agent;
