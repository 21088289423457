import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const user = useSelector((state) => state?.adminSlice?.userData);

  const location = useLocation()


  return (
    <Fragment>
      <div className="iq-sidebar">
        <div className="iq-sidebar-logo d-flex justify-content-between">
          <a href="index.html">
            <div className="iq-light-logo">
              <img
                src="/assets/images/logo_light.png"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="iq-dark-logo">
              <img
                src="/assets/images/logo_dark.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </a>
          <div className="iq-menu-bt-sidebar">
            <div className="iq-menu-bt align-self-center">
              <div className="wrapper-menu">
                <div className="main-circle">
                  <i className="ri-arrow-left-s-line" />
                </div>
                <div className="hover-circle">
                  <i className="ri-arrow-right-s-line" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="sidebar-scrollbar">
          <nav className="iq-sidebar-menu">
            <ul id="iq-sidebar-toggle" className="iq-menu">
              <li className={`${location.pathname == "/" ? 'ms-3 bg-primary text-white rounded' : ""}`}>
                <Link to={"/"}>
                  <span className={`${location.pathname == "/" ? 'text-white ' : "iq-waves-effect "}`}>
                    {/* <span className="iq-waves-effect"> */}
                    <i className="ri-home-4-line" />
                    <span>Dashboard</span>
                  </span>
                </Link>
              </li>
              <li className={`${location.pathname == "/getting-started" ? 'ms-3 bg-primary text-white rounded' : ""}`}>
                <Link to={"/getting-started"}>
                  <span className={`${location.pathname == "/getting-started" ? 'text-white ' : "iq-waves-effect "}`}>

                    {/* <span className="iq-waves-effect"> */}
                    <i className="ri-user-line" />
                    <span>Getting Started</span>
                  </span>
                </Link>
              </li>
              <li className={`${location.pathname == "/users" ? 'ms-3 bg-primary text-white rounded' : ""}`}>

                <Link to={"/users"}>
                  <span className={`${location.pathname == "/users" ? 'text-white ' : "iq-waves-effect "}`}>

                    <i className="ri-user-line" />
                    <span>Users</span>
                  </span>
                </Link>
              </li>
              <li className={`${location.pathname == "/agents" ? 'ms-3 bg-primary text-white rounded' : ""}`}>

                <Link to={"/agents"}>
                  <span className={`${location.pathname == "/agents" ? 'text-white ' : "iq-waves-effect "}`}>


                    <i className="ri-user-line" />
                    <span>Agents</span>
                  </span>
                </Link>
              </li>
              <li className={`${location.pathname == "/interviews" ? 'ms-3 bg-primary text-white rounded' : ""}`}>

                <Link to={"/interviews"}>
                  <span className={`${location.pathname == "/interviews" ? 'text-white ' : "iq-waves-effect "}`}>
                    <i className="ri-user-line" />
                    <span>Interviews</span>
                  </span>
                </Link>
              </li>
              {Boolean(user?.is_enable_two_factor_for_agency) && (
                <li className={`${location.pathname == "/two-factor-code" ? 'ms-3 bg-primary text-white rounded' : ""}`}>

                  <Link to={"/two-factor-code"}>
                    <span className={`${location.pathname == "/two-factor-code" ? 'text-white ' : "iq-waves-effect "}`}>

                      <i className="ri-user-line" />
                      <span>Two Factor Code</span>
                    </span>
                  </Link>
                </li>
              )}
            </ul>
          </nav>
          <div className="p-3" />
        </div>
      </div>
    </Fragment>
  );
};

export default Sidebar;
